#StickerItem {
  position: absolute;
  width: 5.5rem;
  height: 5.5rem;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.15rem;

  &.selected {
    border: 1px solid #fff;
  }

  .close,
  .resize {
    ion-icon {
      background-color: #fff;
      width: 1.25rem;
      height: 1.25rem;
      padding: 0.15rem;
      z-index: 10;
      border-radius: 1rem;
      color: #000;
      font-size: 1rem;
    }
  }

  .close {
    position: absolute;
    top: -1rem;
    right: -1rem;
  }

  .resize {
    position: absolute;
    bottom: -1rem;
    right: -1rem;
  }

  img {
    object-fit: contain;
  }
}
