@import '../../../../theme/fonts';
@import '../../../../theme/colors';

.boulderRankingCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 0.019rem #c0c5d5;
  border-width: 0.063rem;
  padding: 0.5rem 0;

  .boulderRank {
    height: 3rem;
    display: flex;
    align-items: center;
    font-size: 1.125rem;
    font-family: $semiBoldFont;
    width: 2rem;
    color: black;
  }

  .boulderColor {
    width: 2rem;
    height: 2rem;
    min-width: 2rem;
    min-height: 2rem;
    border-radius: 0.5rem;
    margin-right: 0.5rem;
  }

  .boulderInfos {
    display: flex;
    flex-direction: column;
    width: 55%;
    gap: 0.125rem;

    .boulderLevels {
      font-family: $semiBoldFont;
      padding-bottom: 0.125rem;
    }

    .boulderLocation {
      font-family: $regularFont;
      font-size: 12px;
      opacity: 0.4;
    }
  }

  .boulderDate {
    text-transform: capitalize;
    font-size: 12px;
    font-family: $regularFont;
  }

  .flashInfo {
    margin: 0rem 0.5rem;
    .flashIcon {
      color: $warning;
    }
  }

  .daysCount {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .colTitle {
      font-size: 0.8rem;
      position: absolute;
      top: 0;
      color: black;
      font-family: $semiBoldFont;
    }
    width: 60px;
    height: 50px;
    font-family: $semiBoldFont;
    font-size: 14px;
    color: black;
  }
}
