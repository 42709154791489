.smallProfile {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 2.85rem;
  height: 2.5rem;
  cursor: pointer;
  background-color: white;
  .iconStyle {
    position: absolute;
    top: 0;
    left: 0;
    width: 0.75rem;
    height: 0.75rem;
    z-index: 1000;
  }
  .donuts {
    position: relative;
    overflow: hidden;
    display: flex;
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 1.125rem;
    background-color: transparent;
    .profilePictureImage {
      border-radius: 10rem;
      width: 2.25rem;
      height: 2.25rem;
      background-color: lightgray;
      object-fit: cover;
      pointer-events: none;
    }
    .crown {
      z-index: 100;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      width: 1.25rem;
      height: 1.25rem;
      top: -17%;
      left: -23%;
      border-radius: 0.5rem;
      border: solid 0.125rem #17368f;
    }
  }
}

.mediumProfile {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  .iconStyle {
    position: absolute;
    top: 5%;
    left: 0;
    width: 0.8rem;
    height: 0.8rem;
    z-index: 1000;
  }
  .donuts {
    position: relative;
    overflow: hidden;
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 1.125rem;
    background-color: transparent;
    .profilePictureImage {
      border-radius: 50%;
      width: 2.5rem;
      height: 2.5rem;
      background-color: lightgray;
      object-fit: cover;
      pointer-events: none;
    }
    .crown {
      z-index: 100;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      width: 1.6rem;
      height: 1.6rem;
      top: -15%;
      left: -21%;
      border-radius: 0.8rem;
      border: solid 0.125rem #17368f;
    }
  }
}

.largeProfile {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  .iconStyle {
    position: absolute;
    top: 5%;
    left: 0;
    width: 2rem;
    height: 2rem;
    z-index: 1000;
  }
  .donuts {
    position: relative;
    overflow: hidden;
    display: flex;
    width: 7.313rem;
    height: 7.313rem;
    border-radius: 50%;
    background-color: transparent;

    .profilePictureImage {
      border-radius: 50%;
      width: 7.313rem;
      height: 7.313rem;
      object-fit: cover;
      pointer-events: none;
    }
    .crown {
      z-index: 100;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      width: 5rem;
      height: 5rem;
      top: -26%;
      left: -35%;
      border-radius: 2.5rem;
      border: solid 0.125rem white;
    }
  }
}
