#VideoSharing {
  display: flex;
  min-width: 100vw;
  min-height: 100vh;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &.loading {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .videoStatusIcon {
    position: absolute;
    top: 35%;
    left: 50%;
    z-index: 100;
    transform: translate(-50%, -50%);
    opacity: 0;
    display: none;
    transition: all 0.5s 0s ease;
    background-color: var(--ion-background-color-dark);
    box-shadow: 0px 0px 1rem var(--ion-background-color-dark);
    border-radius: 50%;

    ion-icon {
      font-size: 5rem;
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .videoBox {
    display: flex;
    padding: 4rem 3.5rem 2rem;
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    border-radius: 0.5rem;
    overflow: hidden;
    flex-direction: column;
    gap: 1.5rem;
    @media (max-width: 300px) {
      padding: 4rem 1.5rem 2rem;
    }
  }

  .contents {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    height: 15rem;
    gap: 1.5rem;

    &.socialSide {
      justify-content: flex-start;
    }

    .title {
      display: flex;
      font-size: 1.25rem;
      font-weight: bold;
      width: 100%;
      color: #fff;
      align-items: center;
      justify-content: center;
    }
    .info {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .socials {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1.5rem;

      ion-icon {
        background-color: #fff;
        height: 2rem;
        width: 2rem;
        padding: 0.5rem;
        border-radius: 50%;

        &.facebook {
          color: #fff;
          background-color: #3b5998;
        }
        &.twitter {
          color: #fff;
          background-color: #2285e5;
        }
        &.tiktok {
          color: #fff;
          background-color: #000;
        }
        &.discord {
          color: #fff;
          background-color: #545fef;
        }
        &.pinterest {
          color: #fff;
          background-color: #b8081a;
        }

        &.whatsapp {
          color: #fff;
          background-color: #25d366;
        }
        &.snapchat {
          color: #fff;
          background-color: #f8f501;
        }
      }
    }

    ion-button {
      min-height: 2rem;
    }

    ion-toggle {
      color: #fff;
      // padding: 12px;

      --track-background: #fff;
      --track-background-checked: var(--ion-color-primary);

      --handle-background: #fff;
      --handle-background-checked: #fff;

      --handle-height: 4rem;
    }
  }

  video {
    object-fit: cover;
    border-radius: 0.5rem;
    min-width: 100%;
    max-width: 100%;
    max-height: calc(80vh - 10rem);
    object-fit: cover;

    @media (max-width: 300px) {
      max-height: calc(75vh - 12.5rem);
    }
  }

  .footer {
    flex-direction: row;
    justify-content: space-between !important;

    .button {
      display: flex;
      align-items: center;
      justify-self: center;

      padding: 0.65rem;

      border-radius: 50%;
      background-color: #fff;
      border: 1px solid #fff;
      transition: background-color 0.25s 0s ease;

      ion-icon {
        font-size: 1.25rem;
        color: var(--ion-background-color-dark);
      }

      &:active {
        background-color: #ababab;
      }
    }
  }
}
