@import '../../theme/fonts';
@import '../../theme/colors';

.individual_type {
  margin-bottom: 2rem;
  margin-right: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .filtre_title {
    font-family: $boldFont;
    font-size: 1.25rem;
    min-width: 8.75rem;
  }

  .tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.65rem;

    .individualTag {
      display: flex;
      border-color: $black !important;
      font-family: $mediumFont;
      font-size: 1rem;
      padding: 0.563rem 0.75rem;
      border: solid;
      border-radius: 0.5rem;
      border-width: 0.094rem;
      height: 36px;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .individualTag::first-letter {
      text-transform: uppercase;
    }
  }
}
