@import '../../theme/zIndexOrganizer.scss';

#Camera {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;

  .cameraDenied {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .CameraBox {
    position: relative;
    height: 100%;
    width: 100vw;
  }

  .loader {
    --background: transparent;
  }

  video {
    width: 100vw;
    height: 100%;
    object-fit: cover;
  }
}
