@import '../../theme/colors';
@import '../../theme/fonts';

.OnboardingGoalsPage {
  background-color: $dark;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 1.5rem;
  overflow: auto !important;

  .LoginHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: white;
    position: relative;

    h1,
    p {
      width: 100%;
    }

    h1 {
      font-size: 42px;
      line-height: 1;
      font-family: $boldFont;
    }

    p {
      width: 100%;
      line-height: 20px;
      font-size: 1em;
      font-family: $regularFont;
      margin: 0;
      white-space: break-spaces;
    }

    .skipOnboarding {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 100;

      ion-icon {
        width: 1.5em;
        height: 1.5em;
      }
    }
  }

  .OnboardingGoals {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    color: white;
    font-size: 1rem;
    font-family: $regularFont;

    .onboardingGoalsContainer {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .onboardingGoalsStatus {
        display: flex;
        flex-direction: column;
        margin: 1rem 0;
        & > span {
          font-family: $semiBoldFont;
        }

        .goalsStatus {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 0.4rem;
          margin-top: 1rem;

          button {
            flex: 1;
            padding: 0.65rem 1rem;
            border-radius: 0.5rem;
            background-color: transparent;
            border: solid 1px white;
            color: white;

            &:disabled {
              background-color: $light;
              border: solid 1px $light;
            }
          }
        }
      }

      .onboardingGoalsSliders {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        & > span {
          font-family: $semiBoldFont;
        }

        .rangeTitle,
        .rangeValue {
          font-size: 0.938rem;
          font-family: $mediumFont20;
        }
      }
    }

    .actionBtn {
      background-color: $dark;
      color: white;
      margin-top: 2rem;

      &:disabled {
        background-color: lightgray;
      }

      &:focus {
        background-color: #142e82;
      }

      &:active {
        background-color: #142e82;
      }
    }
  }

  .buttonContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 2rem 0;

    button {
      width: 100%;
      height: 42px;
      border-radius: 0.5rem;

      background-color: white;
      color: $light;

      &:disabled {
        opacity: 0.25;
      }

      &.back {
        color: #fff;
        background-color: $light;
      }
    }
  }

  .loginFooter {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    ion-icon {
      width: 10rem;
      height: 2rem;
    }

    p {
      padding-top: 0.5rem;
      font-family: $boldFont;
      margin: 0;
      color: white;
      line-height: 28px;
      white-space: break-spaces;
      font-size: 1.75rem;
    }
  }
}
