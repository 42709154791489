@import './colors';
@import './fonts';

ion-app {
  // margin-top: constant(safe-area-inset-top);
  // margin-top: env(safe-area-inset-top);

  // margin-bottom: constant(safe-area-inset-top);
  // margin-bottom: env(safe-area-inset-bottom);
}

ion-content {
  --padding-bottom: 6rem;
}

.LoginHeader {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);

  padding-bottom: constant(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}

.custom-loader-class {
  --background: transparent !important;
  box-shadow: none !important;
  --spinner-color: var(--ion-color-secondary);

  .loading-wrapper {
    padding-left: unset;
    padding-right: unset;
    -webkit-padding-start: 0;
    padding-inline-start: 0;
    -webkit-padding-end: 0;
    padding-inline-end: 0;
    -webkit-padding-end: 0;
    padding-inline-end: 0;
    width: 0;
  }
}

ion-loading {
  .loading-wrapper {
    --background: transparent !important;
    box-shadow: none !important;
    --width: 2rem;
  }
}

.buddyboulder_logo {
  font-family: $regularFont !important;
}

ion-select::part(icon) {
  width: 1rem !important;
}

ion-button {
  --background-hover: $disabled;
  --background-activated: var(--ion-color-secondary);
  --background-focused: var(--ion-color-secondary);
}

button {
  font-family: $mediumFont20;
  font-size: 1rem;
}

.buddyboulderLogo {
  margin: 0 -0.25rem;
}

.appOpening {
  width: auto;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-height: 100vh;
    min-height: 100vh;
    object-fit: fill;
  }

  .background {
    position: absolute;
    z-index: -1;
    height: 100vh;
    width: 100vw;
    background-position: center;
    background-size: contain;
  }
}
