.test {
  display: inline-block;
  color: red;
}

#Actualite {
  .actualiteFooter {
    background-color: var(--ion-color-primary);
  }
}
