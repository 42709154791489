@import '../../../theme/colors';
@import '../../../theme/fonts';

.homeCartePage {
  padding: 0.5rem 1rem 1.5rem;

  .maCarteName {
    margin-top: -0.8rem;
    text-align: center;
    font-family: $mediumFont;
    font-size: 1.5rem;
  }
  .maCarteTicketMain {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 2rem;
    .maCarteTicket {
      display: flex;
      flex: 0.38;
      justify-content: flex-end;
    }
    .maCarteTicketTextMain {
      flex: 0.62;
      margin-left: 0.5rem;
      .maCarteTicketText {
        font-weight: 500;
        font-family: $mediumFont;
      }
    }
  }
  .maCarteButtons {
    position: absolute;
    bottom: 90px;
    width: 100%;
    margin-top: 0.45rem;
  }
  .maCarteButton {
    font-family: $semiBoldFont;
    text-transform: none;
    font-size: 1.25rem;
    --background: var(--ion-color-secondary);
    --box-shadow: none;
    --padding-top: 0.75rem;
    --padding-bottom: 0.75rem;
    --padding-start: 0.5rem;
    --padding-end: 0.5rem;
    margin: 0rem 1rem;
    padding-right: 2rem;
    width: 100%;
    overflow: hidden;
    --border-radius: 10px;
    span {
      font-family: $semiBoldFont;
    }
  }
  .idContainer {
    border: solid 0.375rem $disabled;
    border-radius: 1.313rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;

    .pictureAndQrc {
      display: flex;
      flex-direction: row;

      .picture {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        .iconProfil {
          height: 6.25rem;
          width: 6.25rem;
        }
      }
      .qrcContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 50%;
        border-left: solid 0.188rem $disabled;
        position: relative;

        .spinnerLoader {
          position: absolute;
          top: 0.125rem;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.25);
          width: 100%;
          height: 9.93rem;
          display: flex;
          align-items: center;
          justify-content: center;

          ion-spinner {
            width: 4rem;
            height: 4rem;
            color: $light;
          }
        }
      }
    }

    .maCarteName {
      width: 100%;
      padding: 1rem 0 0.375rem;
      border-radius: 0 0 0.7rem 0.7rem;
      background-color: $disabled;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 1.625rem;
      font-family: $semiBoldFont;
    }
  }

  .maCarteButtons.notConnected {
    position: relative;
    top: 0;
    // margin-top: 1rem;
    width: 100%;
  }
  .connect-button {
    margin: 1rem;
  }
  .maCarteButtonHorizontal {
    margin: 0;
    width: 100%;
    padding: 0;
  }
  .flexHorizontal {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 0 1rem;
    gap: 1rem;
    margin: 1rem 0;
  }
  .maCarteLogout {
    --background: red;
  }
  .profileFooter {
    background-color: #2a324b;
  }
}
