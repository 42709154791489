@import '../../theme/colors';
@import '../../theme/fonts';

.difficulties {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin: 0.75rem 0rem 1rem 0rem;
  flex-wrap: wrap;

  .difficulty {
    align-self: flex-end;
    position: relative;
    padding: 0.25rem;
    margin: 0 0.05rem;
    border-radius: 0.6rem;

    .difficultyBox {
      min-width: 1.875rem;
      min-height: 1.37rem;
      border-radius: 0.4rem;
      align-self: flex-end;
      position: relative;

      .percent {
        position: absolute;
        font-size: 0.75rem;
        font-family: $semiBoldFont;
        color: white;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 5% auto;
        display: flex;
        justify-content: center;
        align-content: center;
        text-align: center;
      }
    }
  }
}
