#StickersModal {
  --background: rgba(0, 0, 0, 0.5);

  .stickers {
    overflow: auto;
    padding: 4rem 1rem 6rem;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1rem;

    .sticker {
      width: 5.5rem;
      height: 5.5rem;

      object-fit: contain;
    }
  }
}
