/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --ion-background-color-dark: #262b36;
  --ion-background-color-dark-rgb: 44, 44, 44;

  /** primary **/
  --ion-color-primary: #0043af;
  --ion-color-primary-rgb: 0, 67, 175;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #0043af;
  --ion-color-primary-tint: #0043af;

  /** secondary **/
  --ion-color-secondary: #0076ff;
  --ion-color-secondary-rgb: 0, 118, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0076ff;
  --ion-color-secondary-tint: #0076ff;

  /** tertiary **/
  --ion-color-tertiary: #474d61;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #474d61;
  --ion-color-tertiary-tint: #474d61;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** jaune **/
  --ion-color-jaune: #ffff00;
  --ion-color-jaune-rgb: 255, 255, 0;
  --ion-color-jaune-contrast: #000000;
  --ion-color-jaune-contrast-rgb: 0, 0, 0;
  --ion-color-jaune-shade: #e0e000;
  --ion-color-jaune-tint: #ffff1a;

  /** orange **/
  --ion-color-orange: #ffa500;
  --ion-color-orange-rgb: 255, 165, 0;
  --ion-color-orange-contrast: #000000;
  --ion-color-orange-contrast-rgb: 0, 0, 0;
  --ion-color-orange-shade: #e09100;
  --ion-color-orange-tint: #ffae1a;

  /** vert **/
  --ion-color-vert: #008000;
  --ion-color-vert-rgb: 0, 128, 0;
  --ion-color-vert-contrast: #ffffff;
  --ion-color-vert-contrast-rgb: 255, 255, 255;
  --ion-color-vert-shade: #007100;
  --ion-color-vert-tint: #1a8d1a;

  /** bleu **/
  --ion-color-bleu: #0000ff;
  --ion-color-bleu-rgb: 0, 0, 255;
  --ion-color-bleu-contrast: #ffffff;
  --ion-color-bleu-contrast-rgb: 255, 255, 255;
  --ion-color-bleu-shade: #0000e0;
  --ion-color-bleu-tint: #1a1aff;

  /** rouge **/
  --ion-color-rouge: #ff0000;
  --ion-color-rouge-rgb: 255, 0, 0;
  --ion-color-rouge-contrast: #ffffff;
  --ion-color-rouge-contrast-rgb: 255, 255, 255;
  --ion-color-rouge-shade: #e00000;
  --ion-color-rouge-tint: #ff1a1a;

  /** noir **/
  --ion-color-noir: #000000;
  --ion-color-noir-rgb: 0, 0, 0;
  --ion-color-noir-contrast: #ffffff;
  --ion-color-noir-contrast-rgb: 255, 255, 255;
  --ion-color-noir-shade: #000000;
  --ion-color-noir-tint: #1a1a1a;

  /** violet **/
  --ion-color-violet: #800080;
  --ion-color-violet-rgb: 128, 0, 128;
  --ion-color-violet-contrast: #ffffff;
  --ion-color-violet-contrast-rgb: 255, 255, 255;
  --ion-color-violet-shade: #710071;
  --ion-color-violet-tint: #8d1a8d;

  /** rose **/
  --ion-color-rose: #ffc0cb;
  --ion-color-rose-rgb: 255, 192, 203;
  --ion-color-rose-contrast: #000000;
  --ion-color-rose-contrast-rgb: 0, 0, 0;
  --ion-color-rose-shade: #e0a9b3;
  --ion-color-rose-tint: #ffc6d0;
}

.ion-color-jaune {
  --ion-color-base: var(--ion-color-jaune);
  --ion-color-base-rgb: var(--ion-color-jaune-rgb);
  --ion-color-contrast: var(--ion-color-jaune-contrast);
  --ion-color-contrast-rgb: var(--ion-color-jaune-contrast-rgb);
  --ion-color-shade: var(--ion-color-jaune-shade);
  --ion-color-tint: var(--ion-color-jaune-tint);
}

.ion-color-orange {
  --ion-color-base: var(--ion-color-orange);
  --ion-color-base-rgb: var(--ion-color-orange-rgb);
  --ion-color-contrast: var(--ion-color-orange-contrast);
  --ion-color-contrast-rgb: var(--ion-color-orange-contrast-rgb);
  --ion-color-shade: var(--ion-color-orange-shade);
  --ion-color-tint: var(--ion-color-orange-tint);
}

.ion-color-vert {
  --ion-color-base: var(--ion-color-vert);
  --ion-color-base-rgb: var(--ion-color-vert-rgb);
  --ion-color-contrast: var(--ion-color-vert-contrast);
  --ion-color-contrast-rgb: var(--ion-color-vert-contrast-rgb);
  --ion-color-shade: var(--ion-color-vert-shade);
  --ion-color-tint: var(--ion-color-vert-tint);
}

.ion-color-bleu {
  --ion-color-base: var(--ion-color-bleu);
  --ion-color-base-rgb: var(--ion-color-bleu-rgb);
  --ion-color-contrast: var(--ion-color-bleu-contrast);
  --ion-color-contrast-rgb: var(--ion-color-bleu-contrast-rgb);
  --ion-color-shade: var(--ion-color-bleu-shade);
  --ion-color-tint: var(--ion-color-bleu-tint);
}

.ion-color-rouge {
  --ion-color-base: var(--ion-color-rouge);
  --ion-color-base-rgb: var(--ion-color-rouge-rgb);
  --ion-color-contrast: var(--ion-color-rouge-contrast);
  --ion-color-contrast-rgb: var(--ion-color-rouge-contrast-rgb);
  --ion-color-shade: var(--ion-color-rouge-shade);
  --ion-color-tint: var(--ion-color-rouge-tint);
}

.ion-color-noir {
  --ion-color-base: var(--ion-color-noir);
  --ion-color-base-rgb: var(--ion-color-noir-rgb);
  --ion-color-contrast: var(--ion-color-noir-contrast);
  --ion-color-contrast-rgb: var(--ion-color-noir-contrast-rgb);
  --ion-color-shade: var(--ion-color-noir-shade);
  --ion-color-tint: var(--ion-color-noir-tint);
}

.ion-color-violet {
  --ion-color-base: var(--ion-color-violet);
  --ion-color-base-rgb: var(--ion-color-violet-rgb);
  --ion-color-contrast: var(--ion-color-violet-contrast);
  --ion-color-contrast-rgb: var(--ion-color-violet-contrast-rgb);
  --ion-color-shade: var(--ion-color-violet-shade);
  --ion-color-tint: var(--ion-color-violet-tint);
}

.ion-color-rose {
  --ion-color-base: var(--ion-color-rose);
  --ion-color-base-rgb: var(--ion-color-rose-rgb);
  --ion-color-contrast: var(--ion-color-rose-contrast);
  --ion-color-contrast-rgb: var(--ion-color-rose-contrast-rgb);
  --ion-color-shade: var(--ion-color-rose-shade);
  --ion-color-tint: var(--ion-color-rose-tint);
}
