@import '../../theme/fonts';
@import '../../theme/colors';

.profil_info_main {
  padding: 0.325rem 1rem 0.325rem 2.5rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.profil_info_title {
  text-align: right;
  padding-right: 0rem;
  color: rgba(0, 0, 0, 0.85);
  font-size: 1rem;
  font-family: $semiBoldFont;
  min-width: 3rem;
  display: flex;
  justify-content: left;
  align-items: center;
}

.profil_info_text {
  font-size: 1rem;
  font-family: $regularFont;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  border: none;
  border-radius: 0.313rem;
  padding: 0 0.5rem;
  width: calc(100% - 5rem);
  max-width: calc(100% - 5rem);
}
