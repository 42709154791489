@import '../../theme/fonts';
@import '../../theme/colors';

$boulderValueSize: 55px;

.bloc_content {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: var(--ion-color-tertiary-shade);

  .gradient {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(255, 255, 255, 0) 17%,
      rgba(255, 255, 255, 0) 70%,
      rgba(0, 0, 0, 0.4) 100%
    );
    z-index: 1;
  }

  .bloc_picture {
    max-width: unset;
    height: 100%;

    &.empty {
      background-color: var(--ion-color-tertiary-shade);
    }
  }

  .topContainer {
    width: 65%;
    height: 24rem;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    margin-bottom: 80px;
    z-index: 100;

    .topContent {
      background-color: white;
      border-radius: 0.5rem;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      &.sector {
        width: 100%;
        color: black;
        padding: 1.5rem;
        border-radius: 0.5rem;
        margin-bottom: 1rem;
        box-shadow:
          0 0 10px -2px rgba(18, 33, 69, 0.5),
          0 0 30px 0 rgba(18, 33, 69, 0.6);
      }

      .topTitle {
        font-family: $semiBoldFont;
        color: $medium;
        font-size: 3.25rem;
        text-align: center;
      }

      .NoTokenTitle {
        font-family: $boldFont;
        color: $medium;
        font-size: 1.25rem;
        text-align: center;
        margin-top: 1rem;
      }

      .topDescription {
        font-family: $mediumFont;
        font-size: 1.125rem;
        text-align: center;
      }

      .NoTokenDescription {
        font-family: $mediumFont;
        font-size: 1.063rem;
        margin: 0.5rem 0;
        text-align: center;
      }

      ion-button {
        display: flex;
        width: 100%;
        height: 3rem;
        font-family: $semiBoldFont;
        font-size: 1.125rem;
        text-transform: none;
        justify-content: center;
        align-items: center;
        --padding-top: 1rem !important;
        --padding-bottom: 1rem !important;
        --color: white;
        --border-radius: 0.5rem;
        --background: var(--ion-color-primary);
        box-shadow: none;
      }

      .challengPrise {
        font-family: $mediumFont;
        font-size: 0.813rem;
        text-align: center;
        width: 85%;
      }
    }

    .topTick {
      position: absolute;
      bottom: -4%;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0;
      height: 0;
      border-left: 0.625rem solid transparent;
      border-right: 0.625rem solid transparent;
      border-top: 1rem solid white;
    }
  }

  .animation-bounce {
    animation-name: bounce;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
  }

  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    30% {
      transform: translateY(-20px);
    }
    50% {
      transform: translateY(5px);
    }
    100% {
      transform: translateY(0);
    }
  }

  .boulder_header {
    align-items: flex-start;
    font-family: $semiBoldFont;
    font-size: 0.875rem;
    text-transform: capitalize;
    position: absolute;
    color: white;
    width: 100%;
    z-index: 10;
    justify-content: space-between;
    display: flex;
    padding: calc(1rem + env(safe-area-inset-top)) 0.938rem 2.5rem;
    flex-direction: row;
    flex-wrap: wrap;
    background-image: linear-gradient(rgb(0 0 0 / 30%), transparent);
    gap: 0.5rem;

    .boulder_value {
      width: $boulderValueSize;
      min-width: $boulderValueSize;
      max-width: $boulderValueSize;
      text-transform: lowercase;
      white-space: nowrap;
      text-align: center;
      padding: 0.2rem 0.5rem 0.1rem;
      border-radius: 0.438rem;
      font-family: $semiBoldFont;
      font-size: 0.875rem;
      // transform: translateY(-4px);
    }

    .boulder_title {
      flex: 1;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      transform: translateY(3px);
    }

    .dot {
      width: 0.313rem;
      height: 0.313rem;
      border-radius: 9.375rem;
      background-color: white;
      margin: 0 0.25rem;
    }
  }

  .spec_title {
    font-family: $boldFont;
    color: white;
    font-size: 1.25rem;
    margin-left: 0.625rem;
    margin-right: auto;
  }

  .close_icon {
    width: 1.875rem;
    height: 1.875rem;
    transform: translateY(-5px);
  }

  .close_icon:active {
    color: $highlight;
  }

  .bravoIcon {
    height: 0rem;
    animation: 0.5s bravoIn 0.1s forwards;
    -webkit-animation: 0.5s bravoIn 0.1s forwards;
    z-index: 1000;
  }
}

@keyframes bravoIn {
  0% {
    height: 0rem;
  }
  10% {
    transform: rotate(-5deg);
    -webkit-transform: rotate(-5deg);
  }
  20% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  30% {
    transform: rotate(5deg);
    -webkit-transform: rotate(5deg);
  }
  40% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  50% {
    height: 3rem;
    transform: rotate(-5deg);
    -webkit-transform: rotate(-5deg);
  }
  60% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  70% {
    transform: rotate(5deg);
    -webkit-transform: rotate(5deg);
  }
  80% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  90% {
    transform: rotate(-5deg);
    -webkit-transform: rotate(-5deg);
  }
  100% {
    height: 3rem;
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
}

.ShowDpColors {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  padding-top: calc(0.375rem + env(safe-area-inset-top));
  background-color: rgba(0, 0, 0, 0.25);

  .box {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-top: 0.625rem;
    padding-left: 0.938rem;

    .boulder_value {
      width: $boulderValueSize;
      min-width: $boulderValueSize;
      max-width: $boulderValueSize;
      padding: 0.2rem 0.5rem 0.1rem;
      border-radius: 0.438rem;
      align-self: flex-start;
      color: #fff;
      font-family: $semiBoldFont;
      font-size: 0.875rem;
      text-transform: lowercase;
      white-space: nowrap;
      text-align: center;
    }
  }
}
