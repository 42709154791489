@import '../../theme/zIndexOrganizer.scss';

$editorsIcons: 2.125rem;

#VideoEditor {
  display: flex;
  min-width: 100vw;
  min-height: 100vh;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  video {
    min-width: 100vw;
    min-height: 100vh;
  }

  // Icons
  .videoStatusIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    opacity: 0;
    display: none;
    transition: all 0.5s 0s ease;
    background-color: var(--ion-background-color-dark);
    box-shadow: 0px 0px 1rem var(--ion-background-color-dark);
    border-radius: 50%;

    ion-icon {
      font-size: 5rem;
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .videoBox {
    display: flex;
    position: relative;
    min-width: 100vw;
    min-height: 100vh;
  }

  .editors {
    position: absolute;
    bottom: 0;
    right: 1rem;
    left: 1rem;
    bottom: 0;
    gap: 1rem;
    z-index: 100;
    padding: 1rem 0rem 1.5rem;

    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    gap: 2rem;

    .togglers {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .Toggle {
        background-color: rgba(0, 0, 0, 0.25);
        padding: 0.5rem 1rem 0.5rem 0.5rem;
        border-radius: 2rem;
        font-size: 0.9rem;
        width: fit-content;
        gap: 0.5rem;

        $size: 0.9rem;
        .switch {
          width: calc(#{$size} * 3);
          height: calc(#{$size} + 0.5rem);
        }
        .slider:before {
          height: $size;
          width: $size;
          left: calc(#{$size} / 6);
          bottom: calc(#{$size} / 5);
        }
      }
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $editorsIcons;
      height: $editorsIcons;

      padding: 0.25rem;
      border-radius: 0.25rem;
      background-color: rgba(0, 0, 0, 0.25);
      transition: background-color 0.25s 0s ease;

      ion-icon {
        width: 100%;
        height: 100%;
        color: #fff;

        &.disabled {
          opacity: 0.25;
        }
      }

      &.send {
        border: 1px solid #fff;
        width: $editorsIcons * 1.75;
        height: $editorsIcons * 1.75;
        padding-left: 0.4rem;

        border-radius: 50%;
      }
    }
  }
}
#VideoTooShortModal {
  --background: rgba(0, 0, 0, 0.25);

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: #fff;
    padding: 2rem 1.5rem;
    border-radius: 0.5rem;
    width: 75%;
  }
}
