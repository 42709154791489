@import '../../theme/colors';
@import '../../theme/fonts';

.myRankContainer {
  padding: 0 1.25rem;
  display: flex;
  flex-direction: column;
  min-width: 100vw;

  .myRankTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 0.5rem;

    .title {
      font-family: $boldFont;
      font-size: 1.5rem;
      margin-left: 0.75rem;
      margin-right: 0.37rem;
    }
    .title::first-letter {
      text-transform: capitalize;
    }
    .myRankDate {
      font-family: $regularFont;
      font-size: 1.25rem;
      margin-right: 0.37rem;
    }
    .myRankDate::first-letter {
      text-transform: capitalize;
    }
  }

  .infoModal {
    position: absolute;
    background-color: white;
    color: black;
    padding: 1.5rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    box-shadow:
      0 0 10px -2px rgba(18, 33, 69, 0.5),
      0 0 30px 0 rgba(18, 33, 69, 0.6);
    width: 90vw;
    max-height: 17.8rem;
    right: 0;
    left: 0;
    margin: 2.5rem auto;
    z-index: 10;

    .infoRankTitle {
      font-family: $boldFont;
      font-size: 1.25rem;
      white-space: nowrap;
    }

    .infoRankDescription {
      padding-top: 0.5rem;
      font-family: $regularFont;
      font-size: 1rem;
    }

    .infoNotch {
      position: absolute;
      top: -5%;
      right: 20%;
      width: 0;
      height: 0;
      border-left: 0.625rem solid transparent;
      border-right: 0.625rem solid transparent;
      border-bottom: 1rem solid white;
    }
  }

  .myRank {
    background-color: #f5f7fb;
    padding: 0 1.25rem;
    border-radius: 0.4rem;

    .myRankDetails {
      display: flex;
      flex-direction: row;
      padding-top: 1.25rem;

      .detailsInfos {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-left: 1rem;

        .detailName {
          font-family: $semiBoldFont;
          font-size: 1.5rem;
        }

        .detailPoints {
          font-family: $semiBoldFont;
          font-size: 1rem;
          opacity: 0.4;
        }
      }
    }

    .myRankContent {
      width: 100%;
      border-radius: 0.4rem;
      // padding: 0 1.25rem;
      margin: 0.25rem 0;
      display: flex;
      flex-direction: row;

      .myRankPosition,
      .myRankPositionColor {
        width: 50%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0.75rem 0;

        .rankArrows {
          display: flex;
          flex-direction: column;

          .upArrow {
            height: 1.5rem;
            width: 1.5rem;
            margin-bottom: 0.125rem;
            color: #c5c5c5;
            transform: rotate(180deg);
          }
          .downArrow {
            height: 1.5rem;
            width: 1.5rem;
            margin-top: 0.125rem;
            color: #c5c5c5;
          }
        }

        .rankNumber {
          font-family: $semiBoldFont;
          font-size: 3.75rem;
          margin-right: 1.125rem;
          margin-left: 0.5rem;
          text-align: center;
          width: 100%;

          .rank {
            // background-color: red;
            line-height: 1;
          }

          .rankSorting {
            font-size: 0.75rem;
            font-family: $regularFont;
            text-transform: uppercase;
            line-height: 1;
          }
        }
      }

      .myRankPositionColor {
        border-left: solid #c0c5d5 0.038rem;
      }

      .myRankCrown {
        width: 50%;
        display: flex;
        flex-direction: row;
        padding: 0.75rem 0;
        align-items: center;
        justify-content: space-between;

        .crownIcon {
          max-width: 2rem;
          max-height: 2rem;
          min-width: 2rem;
          min-height: 2rem;
          margin: 0 1rem;
        }

        .myRankStatus {
          font-family: $regularFont;
          font-size: 1.125rem;
        }
      }

      .myRankSquare {
        max-width: 2rem;
        max-height: 2rem;
        min-width: 2rem;
        min-height: 2rem;
        margin: 0 1rem;
        border-radius: 0.5rem;
      }
    }
  }

  .toggleByColorLeaderboard {
    display: flex;
    gap: 1rem;
    font-family: $semiBoldFont;
    font-size: 0.875rem;
    align-items: center;
    margin-top: 1rem;
  }
}
