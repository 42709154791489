.manipulation-toolbar {
  background-color: white;
  color: black;
}

.icon-delete {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%) scale(0.1);
  transition: transform 0.3s;
  &.opened {
    transform: translateX(-50%) scale(1);
  }
}
