@import '../../theme/colors';
@import '../../theme/fonts';

.demoPopupBackdrop {
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: flex;
  bottom: 0;
  left: -1.25rem;
  top: 0;
  right: 0;
  z-index: 9999;
}

.demoPopup {
  width: 90%;
  height: 40%;
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 10000;
  align-items: center;

  .demoPopupContent {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: black;
    width: 100%;
    padding: 2rem 2rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    box-shadow:
      0 0 10px -2px rgba(18, 33, 69, 0.5),
      0 0 30px 0 rgba(18, 33, 69, 0.6);

    .demoTitle {
      font-family: $boldFont;
      font-size: 1.5rem;
      text-align: center;
    }

    .demoInput {
      max-height: 2.25rem;
      width: 90%;
      font-size: 1rem;
      color: $accent;
      font-family: $mediumFont;
      border-radius: 0.5rem;
      border: solid 0.063rem $accent;
      text-align: left;
      --placeholder-font-style: $mediumFont !important;
      --placeholder-opacity: 0.6 !important;
      --placeholder-color: var(--ion-color-primary) !important;
      --padding-top: 0.625rem;
      --padding-bottom: 0.625rem;
      --padding-start: 0.625rem;
      --padding-end: 0.625rem;
      margin: 0.625rem;
    }

    .demoDisplay {
      .youtubeIframe {
        border: none;
        border-radius: 0.5rem;
      }

      .demoMediaDiplay {
        width: 6rem;
        height: 10.3rem;
        border-radius: 0.5rem;
        pointer-events: none;
        object-fit: cover;
      }
    }

    .demoIcon {
      width: 2.688rem;
      height: 2.688rem;
      border-radius: 1.34rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid 0.08rem $accent;
      margin: 0.75rem 0;

      .galleryIcon {
        color: $accent;
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .demoButton {
      display: flex;
      width: 90%;
      font-family: $semiBoldFont;
      font-size: 1.125rem;
      text-transform: none;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      height: 2.5rem;
      --color: white;
      --border-radius: 0.5rem;
      --background: var(--ion-color-secondary);
      --padding-top: 0.5rem;
      --padding-bottom: 0.5rem;
      --padding-start: 1.5rem;
      --padding-end: 1.5rem;
      box-shadow: none;
    }

    .alertTick {
      position: absolute;
      bottom: -0.95rem;
      left: 50%;
      width: 0;
      height: 0;
      border-left: 0.625rem solid transparent;
      border-right: 0.625rem solid transparent;
      border-top: 1rem solid white;
      z-index: 100000;
    }
  }
}
