@import '../../theme/colors';
@import '../../theme/fonts';

// Hide all elements
body.barcode-scanner-active {
  visibility: hidden;
  --background: transparent;
  --ion-background-color: transparent;
}

// Show only the barcode scanner modal
.barcode-scanner-modal {
  visibility: visible;
}

@media (prefers-color-scheme: dark) {
  .barcode-scanner-modal {
    --background: transparent;
    --ion-background-color: transparent;
  }
}

.LoginPage {
  background-color: $dark;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 1.5rem;
  position: relative;

  &.hide {
    background-color: transparent;
  }

  .LoginHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: white;
    position: relative;

    h1,
    p {
      width: 100%;
    }

    h1 {
      font-size: 60px;
      line-height: 1;
      font-family: $boldFont;
    }

    p {
      width: 100%;
      line-height: 20px;
      font-size: 1em;
      font-family: $regularFont;
      margin: 0;
      white-space: break-spaces;
    }

    .skipOnboarding {
      position: absolute;
      top: env(safe-area-inset-top);
      right: 0;
      z-index: 100;

      ion-icon {
        width: 1.5em;
        height: 1.5em;
      }
    }
  }

  .login {
    .contentActions {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 4rem;

      button {
        margin-bottom: 0.8rem;
        border-radius: 0.5rem;
        padding: 0.42rem 1rem 0.4rem;
        height: 36px;
        color: white;
        background-color: $light;
        font-size: 1rem;
        font-family: $semiBoldFont;
        line-height: 1.5;

        &:focus {
          background-color: $dark;
        }

        &:active {
          background-color: $dark;
        }

        &.white {
          background-color: #fff;
          color: $dark;
        }
      }

      .loginSkipBtn {
        background-color: transparent;
        border: unset;
        font-weight: bold;
        margin-top: 2rem;
        font-size: 1rem;
        font-family: $semiBoldFont;
      }
    }
  }

  .loginFooter {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    ion-icon {
      width: 10rem;
      height: 2rem;
    }

    p {
      padding-top: 0.5rem;
      font-family: $boldFont;
      margin: 0;
      color: white;
      line-height: 28px;
      white-space: break-spaces;
      font-size: 1.75rem;
    }
  }

  .ScanQrCode {
    position: absolute;
    left: 0;
    right: 0;
    top: constant(safe-area-inset-top);
    top: env(safe-area-inset-top);
    bottom: constant(safe-area-inset-bottom);
    bottom: env(safe-area-inset-bottom);
    padding: 1.5rem;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .head {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      width: 100%;

      .closeIcon {
        display: flex;
        background-color: rgba(0, 0, 0, 0.15);
        border-radius: 1rem;
        padding: 0.5rem;
        color: #fff;

        .icon {
          width: 3rem;
          height: 3rem;
        }
      }
    }
    .spacer {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100%;
      padding: 4rem 0;
      color: #fff;
      font-family: $boldFont;
      font-size: 1.5rem;
      text-align: center;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      margin-top: -2rem;
    }

    .rectangle {
      width: 80vw;
      height: 80vw;
      border: 4px solid #fff;
      border-radius: 0.5rem;
    }
  }
}
