@import '../../theme/colors';
@import '../../theme/fonts';
@import '../../theme/sizes';

.goalsRanges {
  width: 100%;

  .rangeLabels {
    display: flex;
    font-size: 1rem;
    flex-direction: row;
    justify-content: space-between;

    .rangeTitle {
      margin-left: 1rem;
    }

    .rangeTitle::first-letter {
      text-transform: capitalize;
    }
  }

  .rangeLabels::first-letter {
    text-transform: uppercase;
  }

  ion-range {
    --bar-background: rgba(255, 255, 255, 0.5);
    --bar-background-active: white;
    --bar-border-radius: 3.125rem;
    --bar-height: 0.063rem;
    --knob-size: 1.7rem;
    --knob-background: white;
    margin: 0;
    padding: 0;
  }
}
