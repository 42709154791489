@import '../../../../theme/fonts';

.newsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 100vw;
  max-height: 17rem;
  margin-bottom: 2rem;
  --overflow: hidden;
  --padding-bottom: 0rem;

  .loaderBox {
    max-height: 17rem;
  }

  .sectionName {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0.625rem 0.938rem 0;

    #title {
      font-family: $boldFont;
      font-size: 1.5rem;
      margin-left: 0.625rem;
    }
  }

  .NewsSlides {
    border-radius: 0.125rem;
    padding: 0.5rem 1rem;
  }
  .newsSwiper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 16.25rem !important;
    margin: 0 0.5rem;
  }
}
