@import '../../theme/fonts';
@import '../../theme/sizes';
@import '../../theme/colors';

.coachEditionPage {
  background-color: var(--ion-color-primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  z-index: 10;
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }

  .coachEditionContent {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    color: white;
    align-items: center;
    --padding-start: 0.938rem;
    --padding-end: 0.938rem;
    --background: $dark;
    overflow-x: hidden;
    overflow-y: hidden;

    ion-select {
      color: white;
      --placeholder-color: white;
      --placeholder-opacity: 1;
      --padding-top: 0.4rem;
      --padding-bottom: 0.4rem;
      font-family: $semiBoldFont;
      font-size: 1.188rem;
      width: 96%;
    }

    ion-select::part(icon) {
      content: url('../../assets/img/down-arrow.png');
      opacity: 1;
    }

    .secondHeader {
      position: sticky;
      top: 0;
      width: 100%;
      background-color: $dark;
      z-index: 100000;
      .filtreSelector {
        width: 52%;
        min-width: 52%;
        margin-top: 0.1rem;
      }
    }

    .mainBody {
      .colorFilter {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 1rem 0 0.75rem 0;

        .filtreColor {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
          height: 4rem;

          .color {
            width: 1.875rem;
            height: 1.875rem;
            border-radius: 0.5rem;
          }
        }
      }

      .sectionHintColor,
      .sectionHintBoulders {
        font-family: $semiBoldFont;
        font-size: 1.125rem;
        margin-bottom: 0.75rem;
        margin-left: 2rem;
        width: 100%;
        color: white;
      }

      .sectionHintColor {
        padding: 0 0.938rem;
        margin-bottom: 0.35rem;
      }

      .filteredBloc {
        width: 100%;
      }

      .planDetails {
        width: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 0.5rem;
        padding: 1.8rem;
        margin-top: 1.6rem;

        .title {
          color: black;
          font-family: $boldFont;
          font-size: 1.5rem;
          margin-bottom: 0.5rem;
        }

        .planImg {
          display: flex;
          align-items: center;
          width: 100%;
          margin: 1.25rem auto;
          pointer-events: none;
        }
      }
    }
  }

  .terminateButton {
    letter-spacing: 0;
    width: 93%;
    height: 2.875rem;
    min-height: 2.875rem;
    max-height: 2.875rem;
    --border-radius: 0.5rem !important;
    font-family: $semiBoldFont;
    text-transform: none;
    font-size: 1.25rem;
    --background: white;
    --box-shadow: none;
    color: $accent;
    margin: 1rem 0 2.5rem 0;
  }

  .endBackground {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: rgba(29, 55, 138, 0.7);
    z-index: 1000;
  }
}
