.icon-button {
  height: 40px;
  width: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.24);

  &.selected {
    background-color: white;
  }

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}
