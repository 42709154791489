.incrContainer {
  position: relative;
  z-index: 10000;

  .buttonIncr_count {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    margin-left: -1rem;
    min-width: 1.563rem;
    padding: 0 0.35rem;
    height: 1rem;
    border-radius: 0.438rem;
    z-index: 1;

    span {
      font-size: 0.813rem;
      font-weight: bold;
      color: white;
    }
  }

  .buttonIncr_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.625rem;
    padding: 0rem;

    min-width: 2.688rem;
    min-height: 2.688rem;
    border-radius: 13.125rem;
    border: solid;
    border-width: 0.125rem;
    border-color: white;

    .large_nob_icon {
      color: white;
      width: 2rem;
      height: 2rem;
    }
  }
}
