@import '../../theme/colors';
@import '../../theme/fonts';

.AlertCoachHome {
  justify-content: flex-end;
  padding-bottom: calc(7.5rem + env(safe-area-inset-bottom));

  .alertContent {
    bottom: 0;
    width: 90%;
  }
}

#coachHome {
  .coachHomeHeader {
    padding: 3rem 1.25rem 0 1.25rem;
    background-color: white;

    .buddyboulderLogo {
      font-family: $regularFont !important;
      font-size: 1.875rem !important;
      color: $dark;
    }

    .title {
      font-family: $boldFont;
      font-size: 1.5rem;
      margin: 1rem 0.75rem;
      color: black;
    }

    .selectBoxes {
      display: flex;
      flex-direction: row;
      flex: 1;
      justify-content: space-between;
      margin: 0 auto;
      width: 100%;
      max-width: 100%;
    }
  }

  .coachHomeContent {
    width: 100vw;
    --padding-start: 0.38rem;
    --padding-end: 0.38rem;
    --padding-bottom: 12rem;
  }

  .buttonOverlay {
    width: 100%;
    position: absolute;
    bottom: 0;
    margin-bottom: 4.7rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: top;
    background: linear-gradient(rgba(62, 104, 190, 0), rgba(29, 55, 138, 0.6));
    padding: 8% 1rem 5% 1rem;

    .homeButton {
      letter-spacing: 0;
      width: 100%;
      padding: 0.75rem 0.5rem;
      border-radius: 0.5rem !important;
      font-family: $semiBoldFont;
      text-transform: none;
      font-size: 1.25rem;
      color: #fff;
      background: var(--ion-color-secondary);
      box-shadow: none;
    }
  }
}
