#ImageEditor,
#ImageEditorCanvas {
  width: 100%;
  height: 100%;

  .ImageEditorContainer {
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .selected {
    ion-icon {
      stroke: #000;
    }
  }
  ion-icon {
    color: #fff;
    fill: #fff;
  }
}

#ImageEditorCanvas {
  z-index: 0;
}
