@import '../../theme/colors';
@import '../../theme/fonts';

.progressContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 3.75rem;

  .progressCicle {
    width: 3.75rem;
    height: 3.75rem;

    .CircularProgressbar-path {
    }
    .CircularProgressbar-trail {
      stroke: #f5f7fb;
    }
  }

  .progressCicleOff {
    width: 3.75rem;
    height: 3.75rem;

    .CircularProgressbar-path {
    }
    .CircularProgressbar-trail {
      stroke: white;
    }
  }

  .value {
    position: absolute;
    font-family: $semiBoldFont;
    font-size: 1.67rem;
  }
}
