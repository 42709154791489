@import '../../theme/colors';

.boutiqueContent {
  .shopContent {
    position: absolute;
    top: 0;
    --padding-end: 0.938rem;
    --padding-start: 0.938rem;
    --padding-top: 5rem;

    .boutiqueIframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }

  .profileFooter {
    background-color: #2a324b;
  }
}
