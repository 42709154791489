@import '../../theme/fonts';
@import '../../theme/colors';

.accessList {
  background-color: #f2f4f8;
  position: relative;
  // overflow: scroll;
  width: 100%;
  top: 0;
  bottom: 0;
  // fix height by top and bottom
  max-height: calc(100% - 545px);
  padding: 0.688rem 0.538rem;
  border-radius: 0 0 1.313rem 1.313rem;

  .access {
    font-family: $semiBoldFont;
    font-size: 1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;

    width: 100%;
    height: 4rem;
    margin: 0.813rem 0;
    background-color: white;
    border-radius: 0.25rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    padding: 0 1rem;
    flex: 1;

    .infos {
      display: flex;
      flex: 1;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .ticket_container {
      position: relative;
    }

    .recharge {
      font-family: $semiBoldFont;
      font-size: 1.125rem;
      width: 6rem;
      text-align: center;
      color: #ffffff;
      background-color: #55a711;
      border-radius: 7px;
      padding: 0.6rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .accessMessage {
    text-align: center;
    width: 100%;
    line-height: 12.5vh;
    font-family: $regularFont;
    font-size: 1.3rem;
    color: #000000;
  }
}

.loading-animation {
  transition: all 0.5s ease-in-out;
  animation: blink normal 1.5s infinite ease-in-out;
}

.recharge.loading-animation {
  visibility: hidden;
}

@keyframes blink {
  0% {
    background-color: rgb(243, 242, 242);
  }
  50% {
    background-color: rgb(243, 242, 242, 0.5);
  }
  100% {
    background-color: rgb(243, 242, 242);
  }
}
