.dateSelectorModal {
  top: 70%;
  .dateSelectorContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(244, 245, 248);
  }
  .dateSelector {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .dateSelectorOkButton {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 100;
    margin: 10px;
    width: 30px;
    height: 30px;
    --background: transparent;
    --background-activated: transparent;
    --background-focused: transparent;
    --box-shadow: none;
    color: rgb(49, 114, 255);
  }
}

ion-modal.dateSelectorModal {
  --box-shadow: 0;
  --backdrop-opacity: 0;
}

ion-datetime {
  color: #000000;
  background-color: transparent;
  --wheel-fade-background-rgb: transparent;
  --wheel-highlight-background: rgb(240, 240, 240);
  --background-color: transparent;
}
