@import '../../theme/fonts';
@import '../../theme/colors';
@import '../../theme/sizes';

ion-popover::part(content) {
  --width: 50%;
  --max-height: 40%;
  border-radius: 15px;
}

.selectBoxContainer {
  position: relative;
  height: 3.475rem;
  display: flex;
  align-items: center;
  width: 100%;

  .hint {
    position: absolute;
    top: -0.1rem;
    font-size: 0.75rem;
    font-family: $lightFont;
    color: $black;
    background-color: $white;
    margin-left: 0.768rem;
    padding: 0.25rem;
  }

  .box_devider {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-height: 2.375rem;
    min-height: 2.375rem;
    border-radius: 0.5rem;
    border: solid;
    border-width: 0.063rem;
    border-color: $black;
    width: 100%;

    ion-select {
      --padding-start: 0.5rem;
      --padding-end: 0.5rem;
      width: 100%;
    }
  }
}

.selectBoxContainerCoach {
  position: relative;
  height: 3.75rem;
  display: flex;
  align-items: center;
  width: 100%;

  ion-select {
    --padding-start: 0.5rem;
    width: 100%;
  }

  .hint {
    position: absolute;
    top: -0.1rem;
    font-size: 0.75rem;
    font-family: $lightFont;
    color: $white;
    background-color: var(--ion-color-primary);
    margin-left: 0.768rem;
    padding: 0.25rem;
  }

  .medium {
    background-color: $medium;
  }

  .box_devider {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-height: 2.5rem;
    min-height: 2.5rem;
    border-radius: 0.5rem;
    border: solid;
    border-width: 0.063rem;
    border-color: $white;
    width: 100%;

    ion-select {
      font-size: 1rem;
      font-family: $semiBoldFont;
    }
  }
}
