@import '../../../../theme/fonts';
@import '../../../../theme/colors';

.userCard {
  display: flex;
  flex-direction: row;
  padding: 0 1rem;
  gap: 0.75rem;

  .userRank {
    height: 3rem;
    display: flex;
    align-items: center;
    font-size: 1.125rem;
    font-family: $boldFont;
    width: 1.5rem;
  }

  .pictures {
    height: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 3rem;

    .mediumProfile {
      width: 2.625rem;
      height: 2.625rem;
      border-radius: 10rem;
    }
  }

  .userName {
    font-size: 1.125rem;
    font-family: $semiBoldFont;
    height: 3rem;
    display: flex;
    flex: 1;
    gap: 0.5rem;
    align-items: center;
    border-bottom: solid 0.019rem #c0c5d5;
    border-width: 0.063rem;
    // - 1.5rem rank width - 2 x .75rem gaps - 3rem picture
    max-width: calc(100% - 1.5rem - 1.5rem - 3rem);

    .crownIcon {
      color: $citrine;
      margin-left: 1rem;
      width: 1.5rem;
      height: 1.37rem;
    }
  }
}

.myUserCard {
  // box-shadow:
  //   0px 4px 4px rgba(27, 27, 27, 0.15),
  //   0px 0px 12px rgba(126, 140, 176, 0.15);
  border-radius: 0.5rem;
}
