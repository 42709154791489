@import '../../theme/zIndexOrganizer.scss';

#VideoHeader {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 1rem;
  z-index: $CameraPreviewButtons;

  .button {
    display: flex;
    align-items: center;
    justify-self: center;
    padding: 0.25rem;

    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.25);
    border: 1px solid #fff;
    transition: background-color 0.25s 0s ease;

    &.disabled {
      animation: none;
      transition: none;
      opacity: 0.25;
      cursor: not-allowed;
    }

    ion-icon {
      font-size: 1.25rem;
      color: #fff;
    }
    &:active:not(.disabled) {
      background-color: #ababab;
    }
  }

  .children {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: 32px;
    gap: 32px;

    .button {
      border-radius: 0.5rem;
      border: unset;
    }
  }

  .videoTimer {
    color: #fff;
    min-width: 100px;
    border-radius: 0.5rem;
    padding: 0.25rem 1rem;
    background-color: red;
  }

  .smallStatusIndicator {
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    color: #fff;
    ion-icon {
      font-size: 1rem;
    }
  }
}
