@import '../../../../theme/fonts';

.userName {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &.clickable {
    cursor: pointer;
  }
  .userFullName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }

  .userPoints {
    font-family: $semiBoldFont;
    font-size: 1rem;
  }
}
