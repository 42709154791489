@import '../../theme/fonts';
@import '../../theme/sizes';
@import '../../theme/colors';

.usersRanking {
  padding: 1.5rem 1.25rem;
  display: flex;
  flex-direction: column;
  min-width: 100vw;

  .ranking {
    width: 100%;
    border-radius: 0.4rem;
    padding: 0.5rem 0 0;
    margin: 0.37rem 0;
    background-color: #f5f7fb;

    .sectionName {
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;

      font-family: $semiBoldFont;
      font-size: 1.25rem;
      margin-bottom: 2rem;
      margin-left: 1rem;
      text-transform: capitalize;
    }

    .userList {
      background-color: #f5f7fb;
      padding: 0 0.4rem;
      max-height: 300px;
      overflow-y: auto;
    }
    .loadMoreButton {
      text-align: center;
      margin: 1rem;
      font-family: $boldFont;
    }
  }
}
