@import '../../theme/colors';
@import '../../theme/fonts';

.activityContent {
  width: 100%;
  border-radius: 0.4rem;
  background-color: #f5f7fb;
  padding: 0.5rem 0;
  margin: 0.37rem 0;

  .sectionName {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;

    .activityTitle {
      font-family: $semiBoldFont;
      font-size: 1rem;
      margin: 0 0.5rem 0 1rem;
    }

    .activityTitle::first-letter {
      text-transform: capitalize;
    }

    .activityDate {
      font-family: $regularFont;
      font-size: 1rem;
      margin-right: 0.37rem;
    }

    .activityDate:first-letter {
      text-transform: uppercase;
    }
  }

  .chart {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem 0.7rem;
  }
}
