@import '../../theme/fonts';
@import '../../theme/colors';
@import '../../theme/sizes';

.singleDay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0.5rem;
  width: 1.75rem;

  .dayIcon {
    color: $emerald;
    height: 1.75rem;
    width: 1.75rem;
  }

  .dayName,
  .dayDate {
    font-family: $semiBoldFont;
    font-size: 0.875rem;
  }

  .dayDateToday {
    font-family: $boldFont;
    font-size: 0.875rem;
    color: $medium;
  }

  .dayName {
    display: flex;
    opacity: 0.48;
    justify-content: center;
    align-items: center;
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 0.875rem;
  }
}

.singleDay:active {
  color: $emerald;
}
