.ticket_container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.75rem;

  .main_ticket {
    position: relative;
    width: 1.5rem;
    height: 1.875rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .full_ticket {
      position: absolute;
      width: 1.5rem;
      height: 1.875rem;
      border-radius: 0.313rem;
      overflow: hidden;
    }

    .ratio_of_ticket {
      position: absolute;
      background-color: orange;
      width: 1.5rem;
      border-radius: 0 0 0.313rem 0.313rem;
      bottom: 0;
    }

    span {
      position: absolute;
      color: white;
      font-size: 1rem;
      font-weight: bold;
      font-family: sans-serif;
    }
  }

  .circle_one {
    position: absolute;
    top: -0.46rem;
    border-radius: 0.3125rem;
    width: 0.625rem;
    height: 0.625rem;
    background-color: white;
  }

  .circle_two {
    position: absolute;
    bottom: -0.46rem;
    border-radius: 0.3125rem;
    width: 0.625rem;
    height: 0.625rem;
    background-color: white;
  }
}

// increase height and width by 1/3

.ticket_container.big {
  .main_ticket {
    width: 2rem;
    height: 2.35rem;

    .full_ticket {
      width: 2rem;
      height: 2.35rem;
    }

    .ratio_of_ticket {
      width: 2rem;
    }

    span {
      font-size: 1.3rem;
    }
  }

  .circle_one {
    width: 0.678rem;
    height: 0.678rem;
    top: -0.425rem;
    background-color: white;
  }

  .circle_two {
    width: 0.678rem;
    height: 0.678rem;
    bottom: -0.425rem;
    background-color: white;
  }
}
