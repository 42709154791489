@import '../../theme/fonts';

.slideContent {
  text-align: left;

  .slider_img {
    width: 100%;
    height: 10.75rem;
    border-radius: 0.5rem;
    cursor: pointer;
    background-color: lightgray;
    pointer-events: none;
    object-fit: cover;
  }

  .article_info {
    display: flex;
    width: calc(100% - 2rem);
    justify-content: left;
    flex-direction: column;
    margin: 0.313rem 0.938rem;

    .location_info {
      display: flex;
      flex-direction: row;
      font-size: 0.813rem;
      align-items: center;

      .room_name {
        font-family: $semiBoldFont;
        text-transform: capitalize;
      }
      .date {
        font-family: $semiBoldFont;
      }
    }

    .articleTitle {
      padding-top: 0.25rem;
      font-family: $regularFont;
      font-size: 0.875rem;
      text-transform: capitalize;
      line-height: 1rem;
      height: 2.5rem;
      max-width: 14.37rem;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
