#DrawToolbar {
  .draw-toolbar {
    background-color: red;
  }

  .undo-draw {
    position: absolute;
    left: 10px;
    top: 60px;
    margin-top: constant(safe-area-inset-top);
    margin-top: env(safe-area-inset-top);
  }

  .stroke-width-container {
    position: absolute;
    width: 40px;
    left: 15px;
    bottom: 200px;

    .stroke-width-wrapper {
      position: relative;
    }

    .rangeslider__labels {
      display: none;
    }

    .rangeslider__handle {
      width: 20px;
      height: 20px;
      border-radius: 10px;
    }

    .stroke-width-container {
      position: absolute;
    }

    .stroke-width-background {
      padding-top: 9px;
      margin-left: -3px;
      min-height: 200px;
      ion-icon {
        min-height: 200px;
        opacity: 0.75;
        width: 25px;
        height: auto;
      }
    }

    .slider-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .rangeslider-vertical {
        margin: 0 9px;
        height: 100%;
        box-shadow: none;
        .rangeslider__fill {
          opacity: 0;
        }
      }
    }
  }
}
