@import '../../../../theme/fonts';
@import '../../../../theme/sizes';

.sliderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100vw;
  margin-bottom: 2rem;

  .sectionName {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    width: 100%;
    padding: 0 0.938rem;

    .title {
      font-family: $boldFont;
      font-size: 1.5rem;
      margin-left: 0.625rem;
    }

    .questionIcon {
      width: 1.125rem;
      height: 1.125rem;
      margin-left: 0.25rem;
    }
  }

  .slides {
    position: relative;
    border-radius: 0.4rem;
    margin: 0.2rem auto;
    padding: 0 0.938rem;
    display: flex;
    align-content: center;
    justify-content: space-between;
    width: 100vw;

    .flexList {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      flex: 1;
      margin: 0 auto;

      @media (max-width: $maxWidth) {
        max-width: 22.12rem;
      }
      @media (max-width: $midWidth) {
        max-width: 20.25rem;
      }
    }
  }
}
