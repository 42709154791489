@import '../../../../../../theme/fonts';

#BlocChallenge {
  --padding-start: 0 !important;
  --padding-end: 0 !important;
  --overflow: hidden !important;

  .content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 100vh;
    height: 100%;
    padding: 1rem 1.5rem 15rem;
    position: relative;

    .divTitle {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }

    .headInfo {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;

      .selectBoxContainer {
        flex: 1;
        max-width: 65%;
      }

      ion-button {
        display: flex;
        flex: 1;
        width: 100%;
        height: 2.5rem !important;
        --border-radius: 0.5rem;
        text-transform: none;
        font-size: 1rem;
        --box-shadow: none;
        --background: var(--ion-color-primary);
        color: #fff;
        font-family: $semiBoldFont;
      }
    }

    .membersRun,
    .workerMethod {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
      & > span {
        font-size: 1.5rem;
        font-family: $boldFont;
      }

      .headVideosInfo {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        font-size: 1.5rem;
        font-family: $boldFont;

        ion-button {
          font-size: 0.9rem;
          min-width: 8rem;
          max-width: 8rem;
          height: 2rem;
          margin: 0;
          color: #fff;
          --border-radius: 0.5rem;
          text-transform: none;
          font-size: 1rem;
          --box-shadow: none;
          font-family: $semiBoldFont;
        }
      }
    }

    .list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(calc(50% - 3rem), 2fr));
      grid-gap: 1rem;
      grid-auto-rows: minmax(10rem, auto);

      .emptyText {
        min-width: 100%;
        text-align: center;
      }

      &.empty {
        display: block;
      }

      .BlocChallengeVideo {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .videoBloc {
          position: relative;
        }

        .delete {
          position: absolute;
          right: 0.5rem;
          top: 0.5rem;
          font-size: 1rem;
          z-index: 100;
          color: #fff;
          border-radius: 50%;
          background-color: rgba(0, 0, 0, 0.125);
          padding: 0.25rem;
        }

        span {
          font-family: $boldFont;
          padding-left: 0.25rem;
          min-height: 3rem;
          max-height: 3rem;
          display: flex;
          align-items: flex-end;
          word-break: break-all;
        }

        img,
        .OptimizedVideoLoader {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          background-color: #000;
          gap: 1rem;
          color: #fff;
          --color: #fff;
          word-break: keep-all;
          text-align: center;
          border-radius: 1rem;
          overflow: hidden;
          width: 100%;
          height: 15rem;
          object-fit: cover;
          background-size: cover !important;

          & > span {
            word-break: keep-all;
          }

          &.compress {
            padding: 1rem;
          }
        }

        .owner {
          img,
          .OptimizedVideoLoader {
            border: 2px solid var(--ion-color-primary);
          }
        }
      }
    }
  }

  .shareButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    position: fixed;
    bottom: 4rem;
    left: 1rem;
    right: 1rem;

    z-index: 100;
    ion-button {
      min-width: calc(50% - 1rem);
      --background: var(--ion-color-secondary);
    }
  }
}

#DeleteBlocChallengeVideoModal {
  --height: fit-content;
  --width: 90vw;
  --border-radius: 0.5rem;
  --backdrop-opacity: 0.5;

  .modalBody {
    display: flex;
    padding: 1.5rem;
    flex-direction: column;
    gap: 1rem;

    .title {
      font-size: 1.5rem;
      font-family: $boldFont;
    }

    .description {
      font-size: 1rem;
    }
  }
}
