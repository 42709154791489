@import '../../theme/colors';

#DatePickerModal {
  --height: 30vh;
  --border-radius: 1rem 1rem 0 0;
  align-items: flex-end;

  .pickerCloseBtnContainer {
    display: flex;
    justify-content: flex-end;
    background-color: rgb(244, 245, 248);

    .pickerCloseBtn {
      height: 2rem;
      width: 3rem;
      font-size: 16px;
      background-color: transparent;
      margin-top: 0.4rem;
      margin-right: 0.4rem;
      color: $dark;
    }
  }

  .modal-shadow {
    --display: none;
  }
  &::part(content) {
    position: absolute;
    bottom: 0;
    z-index: 10;
  }

  .datePicker {
    color: $dark;
    --ion-color-base: var(--ion-color-secondary) !important;
    margin: 0;
    max-width: unset;
    height: 100%;
  }
}
