.color-swiper {
  width: 100%;
  .swiper-container {
    width: 100%;

    .swiper-wrapper {
      width: 100%;
      .swiper-slide {
        width: 100%;
        display: flex;
        justify-content: space-around;

        .color-item {
          width: 30px;
          height: 30px;
          border-radius: 30px;
          border: solid 2px rgba(255, 255, 255, 0.1);
        }

        .color-item-selected {
          width: 30px;
          height: 30px;
          border-radius: 30px;
          border: solid 2px rgba(255, 255, 255, 1);
        }
      }
    }
  }

  .pagination-container {
    height: 10px;
    width: 100%;
  }

  .swiper-pagination {
    width: 100%;

    .swiper-pagination-bullet {
      margin-left: 5px;
      margin-right: 5px;
      background-color: rgba(255, 255, 255, 0.8);
      &.swiper-pagination-bullet-active {
        background-color: white;
      }
    }
  }
}
