@import '../../theme/fonts';
@import '../../theme/colors';

.blocOpeningContent {
  width: 100vw;
  height: 100vh;
  position: relative;

  .blocPicture {
    max-width: unset;
    height: 100%;

    &.empty {
      background-color: var(--ion-color-tertiary-shade);
    }
  }

  .backButton {
    position: absolute;
    top: 0.25rem;
    left: 0;
    margin: 2.5rem 1.25rem;
    height: 1.5rem;
    width: 1.5rem;
    cursor: pointer;
    z-index: 1000;
    color: #fff;
  }

  .blocHeader {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    color: white;
    font-family: $boldFont;
    font-size: 0.875rem;
    text-transform: capitalize;
    margin: 2.813rem auto;
    z-index: 100;
    flex-wrap: wrap;
    max-width: calc(100% - 8rem);

    .headerColor {
      width: 2rem;
      height: 0.75rem;
      border-radius: 0.375rem;
      border: solid white;
      margin-left: 0.3rem;
    }

    .names {
      margin: 0.15rem;
    }
  }

  .nobs {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 100;
    padding: 0.938rem;
    margin-bottom: 11%;

    .nobIcon {
      color: white;
      margin: 0.44rem 0;
      width: 2.688rem;
      height: 2.688rem;
    }
  }

  .addMedia {
    display: flex;
    position: absolute;
    flex-direction: column;
    text-align: center;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    width: 17.7rem;
    height: 17.6rem;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    margin-top: 40%;
    z-index: 10;
    padding: 2rem 2.5rem;
    border-radius: 0.5rem;
    box-shadow:
      0 0 10px -2px rgba(18, 33, 69, 0.5),
      0 0 30px 0 rgba(18, 33, 69, 0.6);

    .alertTitle {
      color: black;
      font-family: $semiBoldFont;
      font-size: 1.5rem;
    }

    .cameraNob {
      width: 2.688rem;
      height: 2.688rem;
      border-radius: 1.34rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid 0.08rem $accent;

      .cameraIcon {
        color: $accent;
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .galleryButton {
      display: flex;
      width: 90%;
      font-family: $semiBoldFont;
      font-size: 1.125rem;
      text-transform: none;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      height: 2.5rem;
      --color: white;
      --border-radius: 0.5rem;
      --background: var(--ion-color-primary);
      --padding-top: 0.5rem;
      --padding-bottom: 0.5rem;
      --padding-start: 1.5rem;
      --padding-end: 1.5rem;
      box-shadow: none;
    }

    .alertTick {
      position: absolute;
      bottom: -5%;
      left: 0;
      right: 0;
      width: 0;
      height: 0;
      margin: auto;
      border-left: 0.625rem solid transparent;
      border-right: 0.625rem solid transparent;
      border-top: 1rem solid white;
    }

    .linkInput {
      max-height: 2.25rem;
      width: 90%;
      font-size: 1rem;
      color: $accent;
      font-family: $mediumFont;
      border-radius: 0.5rem;
      border: solid 0.063rem $accent;
      text-align: left;
      --placeholder-font-style: $mediumFont !important;
      --placeholder-opacity: 0.75 !important;
      --placeholder-color: var(--ion-color-primary) !important;
      --padding-top: 0.625rem;
      --padding-bottom: 0.625rem;
      --padding-start: 0.625rem;
      --padding-end: 0.625rem;
      margin: 0.625rem;
    }
  }

  .validateMedia {
    display: flex;
    position: absolute;
    flex-direction: column;
    text-align: center;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    width: 17.7rem;
    height: 22.6rem;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    margin-top: 40%;
    z-index: 10;
    padding: 2rem 2.5rem;
    border-radius: 0.5rem;
    box-shadow:
      0 0 10px -2px rgba(18, 33, 69, 0.5),
      0 0 30px 0 rgba(18, 33, 69, 0.6);

    .alertTitle {
      color: black;
      font-family: $semiBoldFont;
      font-size: 1.5rem;
    }

    .cameraNob {
      width: 2.688rem;
      height: 2.688rem;
      border-radius: 1.34rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid 0.08rem $accent;

      .cameraIcon {
        color: $accent;
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .validateMediaButton {
      display: flex;
      width: 30%;
      font-family: $semiBoldFont;
      font-size: 1.125rem;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      height: 2.5rem;
      --color: white;
      --border-radius: 0.5rem;
      --background: var(--ion-color-primary);
      --padding-top: 0.5rem;
      --padding-bottom: 0.5rem;
      --padding-start: 1.5rem;
      --padding-end: 1.5rem;
      box-shadow: none;
    }

    .alertTick {
      position: absolute;
      bottom: -4%;
      left: 0;
      right: 0;
      width: 0;
      height: 0;
      margin: auto;
      border-left: 0.625rem solid transparent;
      border-right: 0.625rem solid transparent;
      border-top: 1rem solid white;
    }

    .vidéoDelete {
      display: flex;
      flex-direction: row;
      justify-content: top;
      width: 10.6rem;

      .trashIcon {
        width: 1.5rem;
        height: 1.75rem;
        color: $accent;
        margin-right: 1rem;
      }

      .youtubeIframe {
        border: none;
        border-radius: 0.5rem;
      }
    }
  }
}

.backButtonEditor {
  position: absolute;
  left: 0;
  margin: 4.5rem 1.25rem;
  width: 0.938rem;
  height: 0.938rem;
  transform: rotate(90deg);
  cursor: pointer;
  z-index: 1000;
}
