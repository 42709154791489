@import '../../theme/zIndexOrganizer.scss';

#VideoFooter {
  display: flex;
  position: absolute;
  bottom: 0;
  z-index: 100000;

  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;

  padding: 1rem;
  max-height: 5.625rem;
  min-height: 5.625rem;

  .range-has-pin {
    padding: 0px;
  }

  button {
    display: flex;
    align-items: center;
    justify-self: center;
    background-color: transparent;

    padding: 1rem;

    border-radius: 50%;
    height: 3rem;
    width: 3rem;
    padding: 0.15rem;
    border: 2px solid #fff;
    transition: background-color 0.25s 0s ease;

    &.camera {
      background-color: red;
      border-radius: 50%;
    }

    &.square {
      animation: colors 1s alternate infinite;
    }

    &.disabled {
      animation: none;
      transition: none;
      opacity: 0.25;
      cursor: not-allowed;
    }

    ion-icon {
      width: 100%;
      &.square {
        font-size: 1.5rem;
      }
      color: red;
    }

    &:active:not(.disabled) {
      background-color: #ababab;
    }
  }

  .footerButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    min-width: 2rem;
    min-height: 2rem;

    padding: 0.25rem;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.25);
    transition: background-color 0.25s 0s ease;

    ion-icon {
      width: 100%;
      height: 100%;
      color: #fff;
    }
  }
}

@keyframes colors {
  from {
    border-color: #fff;
    box-shadow: 0px 0px 10px 5px transparent;
  }
  to {
    border-color: red;
    box-shadow: 0px 0px 10px 5px red;
  }
}
