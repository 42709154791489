@import '../../theme/fonts';
@import '../../theme/colors';
@import '../../theme/sizes';

.calendarContainer {
  position: relative;
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}
