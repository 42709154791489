@import '../../theme/fonts';
@import '../../theme/colors';
@import '../../theme/sizes';

.cardBorder {
  border-radius: 0.8rem;
  border-width: 0.188rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  .colorCard {
    width: 2rem;
    height: 2rem;
    border-radius: 0.5rem;
  }
}
