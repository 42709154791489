.qrcodeBorderPreview {
  align-self: center;
  padding: 0.75rem;
  // margin-top: 1rem;
  height: 9.93rem;
  width: 9.93rem;
  // border: 0.375rem solid #5cbb0d;
  border-radius: 1rem;
  // margin-bottom: 0.7rem;
}
