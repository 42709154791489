$FooterSize: 5.625rem;

#VideoCreator {
  position: relative;
  width: 100vw;
  height: 100vh;

  .fakeBackground {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--ion-background-color-dark);
  }

  .progressModal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--ion-background-color-dark);
    z-index: 100;

    padding: 1rem 4rem;
    display: flex;
    gap: 2rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    color: #fff;
    --color: #fff;
  }

  & > ion-content,
  & > ion-content > div {
    margin-top: constant(safe-area-inset-top);
    margin-top: env(safe-area-inset-top);

    margin-bottom: constant(safe-area-inset-top);
    margin-bottom: env(safe-area-inset-bottom);

    --background: transparent;
    background-color: transparent;

    min-width: 100vw;
    min-height: calc(100vh - calc(constant(safe-area-inset-top) + constant(safe-area-inset-top)));
    min-height: calc(100vh - calc(env(safe-area-inset-top) + env(safe-area-inset-bottom)));

    max-height: calc(100vh - calc(constant(safe-area-inset-top) + constant(safe-area-inset-top)));
    max-height: calc(100vh - calc(env(safe-area-inset-top) + env(safe-area-inset-bottom)));
  }

  & > ion-content > div {
    --background: var(--ion-background-color-dark);
    background-color: var(--ion-background-color-dark);

    &#MobileCamera {
      --background: transparent;
      background-color: transparent;
    }
  }
}
