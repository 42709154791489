@import '../../../../theme/colors';
@import '../../../../theme/fonts';

.introMySessions {
  margin: 1rem 0;

  p {
    padding-top: 0.5rem;
    font-family: $regularFont;
    font-size: 1rem;
    margin: 0 0.5rem 0 1rem;
    width: 80%;
  }

  .sectionName {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;

    .activityTitle {
      font-family: $semiBoldFont;
      font-size: 1.25rem;
      margin: 0 0.5rem 0 1rem;
      text-transform: capitalize;
    }

    .activityDate {
      font-family: $regularFont;
      font-size: 1rem;
    }

    .activityDate:first-letter {
      text-transform: uppercase;
    }
  }
}

.sessionsRecap {
  width: 100%;
  border-radius: 0.4rem;
  background-color: #f5f7fb;
  padding: 1rem;
  margin: 0.37rem 0;

  .title {
    font-family: $regularFont;
    font-size: 0.9rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 0.7rem;

    .number {
      font-family: $semiBoldFont;
      margin-left: 0.5rem;
      font-size: 1rem;
      align-items: center;
    }
  }

  .calendarRecap {
    width: 100%;
    margin-bottom: 1.25rem;
    border-bottom: solid 0.019rem #c0c5d5;
  }

  .calendar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 1.25rem;
    width: 100%;
  }

  .addSessionHint {
    font-family: $regularFont;
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
  }

  .blocRecap {
    .colorSession {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin-left: 0.75rem;
      margin-top: 0.75rem;

      .individualColor {
        width: 18%;
        height: 2rem;
        border-radius: 0.63rem;
        margin: 0.25rem 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-family: $semiBoldFont;
        font-size: 1.125rem;
      }
    }
  }
}
