@import '../../theme/fonts';

.coachPage {
  background-color: var(--ion-color-primary);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  color: white;
  align-items: center;
  overflow-x: hidden;

  .coachContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--ion-color-primary);
  }

  #title {
    font-family: $semiBoldFont;
    font-size: 1.438rem;
    margin-bottom: 0.625rem;
  }

  #secondaryTitle {
    width: 100%;
    text-align: center;
    font-family: $boldFont;
    font-size: 1.438rem;
  }

  #tertiaryTitle {
    font-family: $mediumFont;
    font-size: 0.938rem;
    line-height: 1rem;
    padding-left: 0.5rem;
    margin-bottom: 1.875rem;
  }

  .coachParams {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 0 2.5rem;

    .coachParamsContent {
      width: 18.75rem;
      margin: 2.75rem 0 0;
    }

    .paramsRanges {
      margin-top: 2rem;
      font-family: $mediumFont;
      margin-bottom: 1.875rem;

      .rangeLabels {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0.75rem 0;

        label {
          font-family: $mediumFont;
          font-size: 1.25rem;
        }
      }

      .volumeRange {
        --bar-background: rgba(255, 255, 255, 1);
        --bar-background-active: white;
      }

      ion-range {
        --bar-background: rgba(255, 255, 255, 0.5);
        --bar-background-active: white;
        --bar-border-radius: 3.125rem;
        --bar-height: 0.063rem;
        --height: 1rem;
        --knob-size: 2rem;
        --knob-background: white;
        margin: 0;
        padding: 0;
      }
    }
  }

  .echauffement {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0rem 2.5rem;

    #coachParagraph {
      font-family: $mediumFont;
      font-size: 0.938rem;
      line-height: 1rem;
      padding-left: 0.5rem;
      margin-bottom: 1.87rem;
    }
  }

  .actionButtons {
    width: 90%;
    margin: 2.5rem 0;
    background-color: var(--ion-color-primary);

    button {
      letter-spacing: 0;
      width: 100%;
      padding: 0.75rem 0.5rem;
      border-radius: 0.5rem !important;
      font-family: $semiBoldFont;
      text-transform: none;
      font-size: 1.25rem;
      color: #fff;
      background: var(--ion-color-secondary);
      box-shadow: none;
    }
  }
}
