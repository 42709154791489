@import '../../theme/fonts';
@import '../../theme/sizes';
@import '../../theme/colors';

.coachCircuitPage {
  background-color: var(--ion-color-primary);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;

  #title {
    font-family: $semiBoldFont;
    font-size: 1.438rem;
    margin-bottom: 0.625rem;
  }

  .secondaryTitle {
    font-family: $boldFont;
    font-size: 1.5rem;
  }

  .cicuitDescription {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    padding: 0.5rem 1.5rem;

    .secondaryTitle {
      padding-bottom: 0.5rem;
    }

    .circuitContent {
      font-size: 1.1rem;
      font-family: $mediumFont;
    }
  }

  .flexList {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    overflow-y: auto;
    flex: 1;
    margin: 0 auto;
    width: 100%;
    padding: 0 1.25rem 8rem;
    position: relative;

    .wallItem {
      margin: 0.625rem 0;
      position: relative;
      width: 48%;
      height: 14.063rem;
      min-height: 14.063rem;
      max-height: 14.063rem;
      border-radius: 1.063rem;

      .boulderName {
        position: absolute;
        margin: 0.625rem;
        font-family: $semiBoldFont;
        text-transform: capitalize;
        font-size: 1.125rem;
        z-index: 1000;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 0.25rem;
        padding: 0.25rem;
      }

      .boulderIndex {
        position: absolute;
        right: 0;
        height: 1.5rem;
        width: 1.5rem;
        border-radius: 10rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        color: rgba(0, 0, 0, 0.78);
        margin: 0.75rem;
        font-size: 1rem;
        font-family: $semiBoldFont;
        z-index: 1000;
      }

      .wallImg {
        height: 100%;
        width: 100%;
        min-height: 100%;
        min-width: 100%;
        border-radius: 0.5rem !important;
        object-fit: cover;
        pointer-events: none;
      }

      .doneBackground {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(29, 55, 138, 0.3);
        z-index: 100;

        .done {
          height: 3.5rem;
          width: 3.5rem;
          border-radius: 1.75rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .boulderColor {
        width: 1.063rem;
        height: 1.063rem;
        border-radius: 50%;
        border-width: 0.063rem;
        position: absolute;
        bottom: 0;
        margin: 0.625rem;
        z-index: 1000;
      }
    }
  }

  .circuitActionButtons {
    width: calc(100% - 2.5rem);
    // margin: 1.5rem 0 2.5rem 0;
    position: absolute;
    bottom: 2rem;
    left: 1.25rem;
    right: 1.25rem;
    z-index: 10000;

    button {
      letter-spacing: 0;
      width: 100%;
      padding: 0.75rem 0.5rem;
      border-radius: 0.5rem !important;
      font-family: $semiBoldFont;
      text-transform: none;
      font-size: 1.25rem;
      color: #fff;
      background: var(--ion-color-secondary);
      box-shadow: none;
    }
  }

  .endBackground {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: rgba(29, 55, 138, 0.7);
    z-index: 1000;
  }
}
