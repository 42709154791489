@import '../../theme/colors';
@import '../../theme/fonts';

.ForgotPassword {
  background-color: $dark;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 1.5rem;
  height: 100vh;

  .LoginHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: white;
    position: relative;

    h1,
    p {
      width: 100%;
    }

    h1 {
      font-size: 60px;
      line-height: 1;
      font-family: $boldFont;
    }

    p {
      width: 100%;
      line-height: 20px;
      font-size: 1em;
      font-family: $regularFont;
      margin: 0;
      white-space: break-spaces;
    }

    .skipOnboarding {
      position: absolute;
      top: env(safe-area-inset-top);
      right: 0;
      z-index: 100;

      ion-icon {
        width: 1.5em;
        height: 1.5em;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 2rem;

    .contentLogin,
    .contentActions {
      display: flex;
      flex-direction: column;

      .loginError {
        color: red;
        font-size: 12px;
        margin-left: 0.4rem;
        margin-top: 0.4rem;
      }

      .loginPassword {
        display: flex;
        flex-direction: column;
        position: relative;
      }

      .message {
        margin-left: 0.4rem;
        margin-top: 0.4rem;
        font-size: 12px;
        &.error {
          color: red;
        }
        &.success {
          color: #00ff1e;
        }
      }

      .inputRightIcon {
        position: absolute;
        top: 0;
        bottom: 11px;
        right: 0;
        margin: auto 1rem;
        height: 1.2rem;
        width: 1.2rem;
        color: white;
      }

      .inputLogin {
        background-color: $dark;
        border: solid white 1px;
        color: white;
      }

      .contentLoginBtn {
        background-color: white;
        color: $light;
        margin-top: 1rem;

        &:disabled {
          opacity: 0.25;
        }
      }

      .forgotPassword {
        color: #fff;
        font-family: $semiBoldFont;
        font-size: 1rem;
      }

      input,
      button {
        margin-bottom: 0.8rem;
        border-radius: 0.5rem;
        padding: 0.4rem;
        height: 42px;
      }

      input {
        font-size: 0.875rem;
        font-family: $regularFont20;
        &::-webkit-input-placeholder {
          font-family: $lightFont20;
        }
      }

      input:nth-of-type(2) {
        margin: unset;
      }
    }
  }

  .loginFooter {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    ion-icon {
      width: 10rem;
      height: 2rem;
    }

    p {
      padding-top: 0.5rem;
      font-family: $boldFont;
      margin: 0;
      color: white;
      line-height: 28px;
      white-space: break-spaces;
      font-size: 1.75rem;
    }
  }
}
