@import '../../theme/colors';
@import '../../theme/fonts';

.endCoachContent {
  width: 100%;
  position: absolute;
  bottom: 30%;
  left: 0;
  right: 0;
  margin: auto auto;
  background-color: transparent;
  padding: 0 1.25rem;
  color: black;
  z-index: 10000;

  .lastSessionRecap {
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 0.5rem;
    padding: 2.25rem 0;

    .title {
      font-size: 1.5rem;
      font-family: $boldFont;
      margin: 0 1rem;
    }

    .addBlocHint {
      .addTitle {
        font-size: 1.25rem;
        font-family: $boldFont;
        margin: 0 1.13rem;
        padding-bottom: 0.5rem;
      }

      .description {
        font-size: 1rem;
        font-family: $regularFont;
        margin: 0 1.13rem;
        text-align: left;
      }
    }

    .addButtonContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 1.25rem;
    }
  }

  .endButton,
  .addButton {
    letter-spacing: 0;
    font-family: $semiBoldFont;
    width: 100%;
    font-size: 1.25rem;
    border-radius: 0.5rem;
    padding: 0.75rem 0.5rem;
    border-width: 0.063rem;
    color: var(--ion-color-secondary);
    background: white;
    box-shadow: none;
    text-transform: none;
    margin-top: 2rem;
    height: 2.8rem !important;
  }

  .addButton {
    width: 40%;
    color: white;
    background: var(--ion-color-secondary);
    margin: 0 auto;
  }
}
