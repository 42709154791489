@import '../../theme/colors';
@import '../../theme/fonts';

.levelContent {
  width: 100%;
  border-radius: 0.4rem;
  padding: 0.5rem 0;
  margin: 0.37rem 0;

  .sectionName {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    padding-bottom: 1rem;

    .levelTitle {
      font-family: $semiBoldFont;
      font-size: 1rem;
      margin: 0 0.5rem 0 1rem;
    }

    .levelTitle:first-letter {
      text-transform: uppercase;
    }

    .levelDate {
      font-family: $regularFont;
      font-size: 1rem;
    }

    .levelDate:first-letter {
      text-transform: uppercase;
    }
  }

  .levels {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem 0.7rem;

    .levelItem {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .levelType {
        font-family: $semiBoldFont;
        font-size: 0.875rem;
        text-transform: uppercase;
        margin-top: 0.37rem;
      }
    }
  }
}
