@import '../../theme/zIndexOrganizer.scss';

$editorsIcons: 2.5rem;

#VideoTrim {
  display: flex;
  min-width: 100vw;
  min-height: 100vh;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  // Icons
  .videoStatusIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    display: none;
    transition: all 0.5s 0s ease;
    background-color: var(--ion-background-color-dark);
    box-shadow: 0px 0px 1rem var(--ion-background-color-dark);
    border-radius: 50%;

    ion-icon {
      font-size: 5rem;
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .videoBox {
    display: flex;
    padding: 4rem 1.5rem 6rem;
    width: 100vw;
    max-height: 100vh;
    border-radius: 0.5rem;
    overflow: hidden;
  }

  video {
    min-width: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
  }

  // Footer
  .footer {
    flex-direction: row;
    justify-content: space-between !important;

    .button {
      display: flex;
      align-items: center;
      justify-self: center;

      padding: 0.65rem;

      border-radius: 50%;
      background-color: #fff;
      border: 1px solid #fff;
      transition: background-color 0.25s 0s ease;

      ion-icon {
        font-size: 1.25rem;
        color: var(--ion-background-color-dark);
      }

      &:active {
        background-color: #ababab;
      }
    }
  }
}
