@import '../../../theme/fonts';

.ProfilNotifications {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 2.5rem;

  .head {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  .title {
    font-size: 1.25rem;
    font-family: $boldFont;
  }

  .subtitle {
    font-size: 1rem;
    font-family: $regularFont;
  }

  .disclaimer {
    font-size: 0.8rem;
    font-family: $regularFont;
  }

  .toggleBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: space-between;

    .label {
      font-family: $semiBoldFont;
      font-size: 1rem;
      width: 7.5rem;
    }
    .Toggle {
      color: #000;

      input:checked + .slider {
        background-color: transparent;
      }
      input:checked + .slider:before {
        opacity: 1;
      }
      .slider:before {
        opacity: 0.5;
      }
    }
  }
}
