$height: 3.625rem;

#VideoTrimer {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  z-index: 100;

  .timer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    color: #fff;
    padding: 0 1rem;
    font-size: 0.8rem;
  }

  #ImgCanvas {
    display: none;
  }

  #VideoTrimmerRange,
  .imagesBox {
    width: 100%;
    height: 100%;
    max-width: 100%;
    min-height: $height;
    max-height: $height;

    border: 2px solid #fff;
    border-left: none;
    border-right: none;
    border-radius: 0.5rem;
  }

  .imagesBox {
    display: flex;
    flex: 1;
    flex-direction: row;
    overflow: hidden;

    &.true {
      padding: 1rem;
      align-items: center;
      justify-content: center;
    }

    ion-spinner {
      height: 1rem;
      color: #fff;
    }

    img {
      max-height: 100%;
      max-width: calc(100% / 4);
      object-fit: cover;
    }
  }

  .VideoTrimmerRange {
    position: absolute !important;
    width: 100%;
    height: $height;
    display: flex;
    align-items: center;

    .thumb {
      width: 1rem;
      height: calc(#{$height} - 0.5rem);
      background-color: #fff;
      border: 1px solid #000;
      border-radius: 1rem;

      .littlePop {
        position: absolute;
        background: #fff;
        padding: 0.15rem 0.5rem;
        border-radius: 0.5rem;
        top: 0rem;
        left: 0;
        transform: translate(calc(-50% + 0.25rem), calc(-100% - 0.75rem));
        font-size: 0.8rem;
      }
    }

    .track {
      height: $height;
      border: 1px solid #fff;
      border-left: none;
      border-right: none;
      border-radius: 0.5rem;

      &-0,
      &-2 {
        background: repeating-linear-gradient(
          -55deg,
          rgba(0, 0, 0, 0.5),
          rgba(0, 0, 0, 0.5) 0.25rem,
          rgba(100, 100, 100, 0.5) 0.25rem,
          rgba(100, 100, 100, 0.5) 0.5rem
        );
      }
    }
  }
}
