.OptimizedVideo {
  background-color: var(--ion-background-color-dark);
  --border-radius: 0;
  --background: var(--ion-background-color-dark);
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);

  .close {
    position: absolute;
    right: 1rem;
    top: 1rem;

    &.ios {
      top: 0.25rem;
      right: 2.5rem;
    }
    color: #fff;
    border: 1px solid #fff;
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    padding: 0.15rem;
    font-size: 1.15rem;
    z-index: 100;
  }

  .share {
    position: absolute;
    right: 0.5rem;
    bottom: 6rem;
    color: #fff;
    background: radial-gradient(rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
    border-radius: 50%;
    padding: 0.75rem;
    font-size: 1.25rem;
    z-index: 100;
  }

  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

#OptimizedVideoModalLoader {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 200;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.45);

  ion-spinner {
    height: 4rem;
    color: #fff;
  }
}
