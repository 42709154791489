.qrcodeMain {
  display: flex;
  justify-content: center;
}

.qrcodeClose {
  position: absolute;
  right: 0px;
  width: 1.5rem;
  height: 1.5rem;
  margin: 2.25rem;
  text-align: center;
}

.qrcodeUser {
  padding-top: 40%;
  text-align: center;
}

.qrcodeBorder {
  align-self: center;
  padding: 0.8rem;
  margin-top: 1rem;
  height: 15rem;
  width: 15rem;
  border: 0.4rem solid #5cbb0d;
  border-radius: 1rem;
}

.qrcodePicture {
  width: 30px;
  height: 30px;
  margin: 58px 4px 8px 51px;
  border-radius: 50%;
  pointer-events: none;
}

.qrcodeImg {
  border-radius: 1rem;
}
