@import '../../theme/fonts';
@import '../../theme/colors';
@import '../../theme/sizes';

$lowerZ: 0;
$mediumZ: 100;
$upperZ: 101;

.boulderCard {
  margin: 0.625rem 0;
  position: relative;
  width: 10.62rem;
  min-width: 10.62rem;
  max-width: 10.62rem;
  height: 14.063rem;
  min-height: 14.063rem;
  max-height: 14.063rem;
  border-radius: 0.5rem;
  color: white;

  @media (max-width: $midWidth) {
    width: 9.75rem;
    max-width: 9.75rem;
    min-width: 9.75rem;
    height: 14.063rem;
    min-height: 14.063rem;
    max-height: 14.063rem;
  }

  .wallName {
    position: absolute;
    margin: 0.625rem;
    top: 0;
    font-family: $semiBoldFont;
    text-transform: capitalize;
    font-size: 1.125rem;
    z-index: 60;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 0.25rem;
    padding: 0.25rem;
  }

  .boulderPicture {
    height: 100%;
    width: 100%;
    min-height: 100%;
    min-width: 100%;
    border-radius: 0.5rem !important;
    object-fit: cover;
    pointer-events: none;
  }

  .tickIcon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(29, 55, 138, 0.3);
    z-index: $mediumZ;

    // .done {
    //   height: 3.5rem;
    //   width: 3.5rem;
    //   border-radius: 1.75rem;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    // }
  }

  .gradient {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(255, 255, 255, 0) 20%);
    border-radius: 0.5rem;
    z-index: $lowerZ;
  }

  .auditBackground {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background-color: rgba(29, 55, 138, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: $lowerZ;
  }
  .auditContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60%;
  }

  .validated {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background-color: rgba(29, 55, 138, 0.3);
    border-radius: 0.5rem;
    z-index: $mediumZ;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .iconFlashFull,
  .iconTopFull {
    width: 2.688rem;
    height: 2.688rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &.disabled {
      opacity: 0.5;
    }
  }

  .animation-zoomin {
    animation-name: zoomin;
    animation-duration: 1s;
    animation-timing-function: linear;
  }

  @keyframes zoomin {
    0% {
      transform: scale(0.85, 0.85);
    }
    33% {
      transform: scale(1, 1);
    }
    66% {
      transform: scale(0.85, 0.85);
    }
    100% {
      transform: scale(1, 1);
    }
  }

  .iconContainer {
    width: 2.688rem;
    height: 2.688rem;
    border-radius: 1.344rem;
    border: solid;
    border-width: 0.125rem;
    border-color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &.opaque {
      border-color: white;
    }

    .iconTop {
      width: 1.125rem;
      height: 0.875rem;
      opacity: 0.5;
      &.opaque {
        opacity: 1;
      }
    }

    .iconFlash {
      width: 1.5rem;
      height: 1.5rem;
      opacity: 0.5;
      &.opaque {
        opacity: 1;
      }
    }
    .popup {
      visibility: visible;
      position: absolute;
      width: 8rem;
      background-color: white;
      color: black;
      z-index: $upperZ;
      text-align: center;
      padding: 0.5rem;
      border-radius: 0.5rem;
      font-family: $semiBoldFont;
      box-shadow:
        0 0 10px -2px rgba(18, 33, 69, 0.5),
        0 0 30px 0 rgba(18, 33, 69, 0.6);
      &.onBottom {
        top: 3rem;
      }
      &.onTop {
        bottom: 3rem;
      }
    }
    .alertTick {
      position: absolute;
      left: 30%;
      width: 1rem;
      height: 1rem;
      border-left: 0.5rem solid transparent;
      border-right: 0.5rem solid transparent;
      z-index: $upperZ;
      &.onBottom {
        top: 2rem;
        border-bottom: 0.5rem solid white;
      }
      &.onTop {
        bottom: 2rem;
        border-top: 0.5rem solid white;
      }
    }
  }

  .boulderColor {
    width: 1.063rem;
    height: 1.063rem;
    border-radius: 50%;
    border-width: 0.063rem;
    position: absolute;
    bottom: 0;
    margin: 0.625rem;
    z-index: $mediumZ;
  }
}
