$size: 1rem;

.Toggle {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  color: #fff;

  .switch {
    position: relative;
    display: inline-block;
    width: calc(#{$size} * 3);
    height: calc(#{$size} + 0.5rem);

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    border: 1px solid currentColor;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: '';
      height: $size;
      width: $size;
      left: calc(#{$size} / 5);
      bottom: calc(#{$size} / 5);
      background-color: currentColor;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }

  input {
    &:checked + .slider {
      background-color: var(--ion-color-primary);
    }

    &:focus + .slider {
      box-shadow: 0 0 1px var(--ion-color-primary);
    }

    &:checked + .slider:before {
      -webkit-transform: translateX(150%);
      -ms-transform: translateX(150%);
      transform: translateX(150%);
    }
  }

  /* Rounded sliders */
  .slider {
    &.round {
      border-radius: 34px;
      &:before {
        border-radius: 50%;
      }
    }
  }
}
