@import '../../theme/fonts';
@import '../../theme/sizes';
@import '../../theme/colors';

.selectWheelContainer {
  position: relative;
  height: 3.475rem;
  display: flex;
  width: 100%;
  align-items: center;
  background-color: white;

  .hint {
    position: absolute;
    top: -0.1rem;
    font-size: 0.75rem;
    font-family: $lightFont;
    color: $black;
    background-color: $white;
    margin-left: 0.768rem;
    padding: 0.25rem;
  }

  .boxDevider {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    max-height: 2.375rem;
    min-height: 2.375rem;
    border-radius: 0.5rem;
    border: solid;
    border-width: 0.063rem;
    border-color: $black;
    padding: 0 0.75rem;
    width: 100%;

    .buttonSelect {
      font-family: $semiBoldFont !important;
      font-size: 1rem;
      color: $black;
    }
    .buttonSelect:first-letter {
      text-transform: uppercase;
    }

    .arrowIcon {
      height: 1.2rem;
      width: 1.2rem;
      color: #fff;
      &.blue {
        color: $light;
      }
    }
  }
}

.selectWheelContainerCoach {
  position: relative;
  height: 3.475rem;
  display: flex;
  width: 100%;
  align-items: center;
  background-color: $dark;

  .hint {
    position: absolute;
    top: -0.1rem;
    font-size: 0.75rem;
    font-family: $lightFont;
    color: white;
    background-color: $dark;
    margin-left: 0.938rem;
    padding: 0 0.188rem;
  }

  .boxDevider {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    max-height: 2.375rem;
    min-height: 2.375rem;
    border-radius: 0.5rem;
    border: solid;
    border-width: 0.063rem;
    border-color: white;
    padding: 0 0.75rem;
    width: 100%;

    .buttonSelect {
      font-family: $semiBoldFont !important;
      font-size: 1.13rem;
      color: white;
    }
    .buttonSelect:first-letter {
      text-transform: uppercase;
    }

    .arrowIcon {
      height: 1.2rem;
      width: 1.2rem;
    }
  }
}
