@import '../../theme/colors';
@import '../../theme/fonts';

.alertBackdrop {
  position: fixed;
  display: flex;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  z-index: 9999;
}

.alert {
  width: 80%;
  position: fixed;
  display: flex;
  text-align: center;
  flex-direction: column;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  // margin: 0 auto;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;

  .alertContent {
    background-color: white;
    color: black;
    padding: 1rem 1.5rem;
    border-radius: 0.5rem;
    width: 90%;
    box-shadow:
      0 0 10px -2px rgba(18, 33, 69, 0.5),
      0 0 30px 0 rgba(18, 33, 69, 0.6);

    .alertTitle {
      font-family: $boldFont;
      font-size: 1.25rem;
    }

    .alertDescription {
      font-family: $mediumFont;
      font-size: 1.063rem;
      margin: 0.5rem 0;
    }

    .registerButton {
      display: flex;
      width: 60%;
      font-family: $semiBoldFont;
      text-transform: none;
      justify-content: center;
      align-items: center;
      margin: auto auto;
      color: white;
      border-radius: 0.5rem;
      background: var(--ion-color-secondary);
      box-shadow: none;
      padding: 0.5rem;
      margin-top: 1rem;
    }
  }

  .alertTick {
    width: 0;
    height: 0;
    border-left: 0.625rem solid transparent;
    border-right: 0.625rem solid transparent;
    border-top: 1rem solid white;
  }
}
