@import '../../../../theme/colors';
@import '../../../../theme/fonts';

.lastMonthsRecap {
  width: 100%;
  border-radius: 0.4rem;
  background-color: #f5f7fb;
  padding: 1.25rem;
  margin: 0.37rem 0;

  .recapTitle {
    font-family: $semiBoldFont;
    font-size: 1.1rem;
  }

  .title {
    font-family: $regularFont;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    margin-left: 0.75rem;
    align-items: center;

    .number {
      font-family: $semiBoldFont;
      margin-left: 0.5rem;
      align-items: center;
    }
  }

  .monthsNumbers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-bottom: solid 0.019rem #c0c5d5;
    padding: 0.75rem 0;
  }

  .colorRecap {
    width: 100%;
    border-bottom: solid 0.019rem #c0c5d5;
    padding: 0.75rem 0;
    .title {
      white-space: nowrap;
    }
  }

  .plugsNumbers {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.75rem 0;
  }
}
