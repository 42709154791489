@import '../../theme/colors';
@import '../../theme/fonts';

.home {
  padding-bottom: 2.75rem;

  .homeFooter {
    background-color: var(--ion-color-primary);
  }

  .progress {
    width: 100%;
    padding: 0 0.5rem;

    .progressTitle {
      font-family: $boldFont;
      font-size: 1.5rem;
      margin: 0.5rem 1rem;
    }
  }

  .homeContent {
    --padding-bottom: 7rem;

    .myRankContainer {
      padding: 1rem;
    }
  }

  .buttonOverlay {
    width: 100%;
    position: absolute;
    bottom: 0;
    margin-bottom: 4.7rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: top;
    background: linear-gradient(rgba(62, 104, 190, 0), rgba(69, 79, 114, 0.6));
    padding: 8% 1rem 5% 1rem;

    .homeButton {
      letter-spacing: 0;
      font-family: $semiBoldFont;
      font-size: 1.25rem;
      width: 50% !important;
      min-height: 2.875rem;
      min-width: 8.75rem;
      margin: 0;
      color: #fff;
      background: var(--ion-color-primary);
      border-radius: 0.5rem;
      box-shadow: none;
      width: 100px !important;
      text-transform: none;
      padding-left: 0 !important;
      padding-right: 0 !important;

      &:not(:last-child) {
        margin-right: 0.5rem;
      }

      .buttonIcon {
        height: 1.5rem;
        width: 1.5rem;
        margin-right: 0.5rem;
      }
    }

    .openingButton {
      letter-spacing: 0;
      width: 100%;
      padding: 0.75rem 0.5rem;
      border-radius: 0.5rem !important;
      font-family: $semiBoldFont;
      text-transform: none;
      font-size: 1.25rem;
      color: #fff;
      background: var(--ion-color-secondary);
      box-shadow: none;
    }
  }
}
