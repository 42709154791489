@import '../../../theme/fonts';
@import '../../../theme/colors';

.headerContent {
  padding: 1.87rem 0.938rem 0rem 0.938rem;
  padding-top: max(1.87rem, env(safe-area-inset-top));
  background-color: white;
  width: 100vw;

  @media (prefers-color-scheme: dark) {
    .darkMode {
      width: 100%;
      height: max(1.7rem, env(safe-area-inset-top));
      background: white;
      position: absolute;
      background: linear-gradient(rgba(62, 104, 190, 0.6), rgba(62, 104, 190, 0));
      top: 0;
      left: 0;
    }
  }

  ion-select {
    color: $black;
    --placeholder-color: $black;
    --placeholder-opacity: 1;
    --padding-top: 0.4rem;
    --padding-bottom: 0.4rem;
    --padding-start: 0.5rem;
    font-family: $semiBoldFont;
    font-size: 0.938rem;
    width: auto;
    max-width: 100%;
  }

  ion-select::part(icon) {
    content: url('../../../assets/img/arrow.png');
    opacity: 1;
    margin-left: 0.5rem;
  }

  .headerTop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    background-color: white;
    margin-bottom: 0.5rem;
    align-items: center;
    margin-top: 0.25rem;

    .buddyboulderLogo {
      width: 57.5vw;
      height: 2rem;
      padding-top: 0.25rem;
    }

    .infoUser {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 5rem;
    }

    .loggedUser {
      width: 4.688rem;
    }

    .ticket {
      display: flex;
      background-color: #5cbb0d;
      width: 2rem;
      height: 2.5rem;
      border-radius: 0.313rem;
      justify-content: center;
      align-items: center;
    }

    .signInButton {
      height: 2.188rem !important;
      min-height: 2.188rem !important;
      max-height: 2.188rem !important;
      border-radius: 0.4rem !important;
      font-family: $semiBoldFont;
      text-transform: none;
      font-size: 1rem;
      background: var(--ion-color-secondary);
      box-shadow: none;
      color: white;
      letter-spacing: 0;
      padding: 1px 10px 0;
      line-height: 1;
    }
  }

  .headerBottom {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-width: 18.75rem;
    background-color: white;
    margin-bottom: 0.7rem;
    gap: 1rem;

    .boutiqueTitle {
      font-family: $mediumFont;
      font-size: 1.563rem;
      color: $dark;
    }

    .headerBottomLeftRoom {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 1;
      gap: 0.5rem;
      max-width: 100%;

      button,
      .selectBoxContainer {
        max-width: calc(calc(100vw - 2.376rem) / 2);
        min-width: calc(calc(100vw - 2.376rem) / 2);
      }

      button {
        white-space: nowrap;
        height: 38px;
        border-radius: 0.5rem;
        padding: 0.48rem 1rem 0.4rem;
        color: white;
        background-color: $light;
        font-family: $semiBoldFont;
        font-size: 1.25rem;
        line-height: 0.5;

        &:focus {
          background-color: $dark;
        }

        &:active {
          background-color: $dark;
        }

        &.white {
          background-color: #fff;
          color: var(--ion-color-secondary);
        }
      }
    }

    .headerBottomLeftDate {
      width: 44%;
      min-width: 44%;
    }
    .headerRightWall {
      width: 44%;
      min-width: 44%;
    }
    .headerRightRoom {
      width: 52%;
      min-width: 52%;
    }
  }
  .sectors {
    width: 38%;
    min-width: 38%;
  }
}

.headerContentCoach {
  padding: 1.87rem 0.938rem 0rem 0.938rem;
  padding-top: max(1.87rem, env(safe-area-inset-top));
  width: 100vw;

  ion-select {
    color: white;
    --placeholder-color: white;
    --placeholder-opacity: 1;
    --padding-top: 0.4rem;
    --padding-bottom: 0.4rem;
    font-family: $semiBoldFont;
    font-size: 0.938rem;
    width: 96%;
  }

  ion-select::part(icon) {
    content: url('../../../assets/img/down-arrow.png');
    opacity: 1;
    margin-left: 0.5rem;
  }

  .headerTop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0.5rem;

    .coachGreating {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #fff;

      .backButton {
        top: 0.25rem;
        width: 1.25rem;
        height: 1.25rem;
        // margin-top: 0.35rem;
        margin-right: 0.75rem;
        color: #fff;
      }

      .backButton:active {
        color: $highlight;
      }

      .title {
        font-family: $semiBoldFont;
        font-size: 1rem;
      }
    }

    .infoUser {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 5rem;
    }

    .loggedUser {
      width: 4.688rem;
    }

    .ticket {
      display: flex;
      background-color: #5cbb0d;
      width: 2rem;
      height: 2.5rem;
      border-radius: 0.313rem;
      justify-content: center;
      align-items: center;
    }

    .signInButton {
      font-family: $semiBoldFont;
      font-size: 1rem;
      border-radius: 0.313rem;
      border-width: 0.063rem;
      color: white;
      border-color: white;
      text-transform: none;
      height: 2.188rem !important;
      min-height: 2.188rem !important;
      max-height: 2.188rem !important;
    }
  }

  .headerBottom {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-width: 18.75rem;

    .headerBottomLeftRoom {
      min-width: 52%;
      padding: 0 1rem;
    }
    .headerBottomLeftDate {
      min-width: 44%;
      max-width: 100%;
    }
    .headerRightWall {
      width: 44%;
      min-width: 44%;
    }
    .headerRightRoom {
      width: 52%;
      min-width: 52%;
    }
  }
  .sectors {
    width: 38%;
    min-width: 38%;
    padding-left: 1rem;
  }
}

.headerBottom {
  margin-top: 1rem;
  .roomDisabled {
    position: relative;
    gap: 0.5rem;
    color: white;
    padding: 0.4rem;
    border-radius: 0.4rem;
    border: 1px solid #fff;
    max-width: 100%;
    width: auto;

    span {
      top: -0.75rem;
      left: -0.25rem;
      position: absolute;
      font-size: 0.85rem;
      font-family: $regularFont;
      color: $white;
      background-color: var(--ion-color-primary);
      margin-left: 0.768rem;
      padding: 0.25rem;
    }

    div {
      font-family: $semiBoldFont;
      font-size: 1rem;
      margin-top: 0.25rem;
      padding: 0.115rem 0.25rem;
      height: 22px;
    }
  }
}
