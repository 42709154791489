@import '../../theme/fonts';
@import '../../theme/colors';
@import '../../theme/sizes';

.boulderSelector {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
  margin: 0 auto;

  @media (max-width: $maxWidth) {
    max-width: 22.12rem;
  }
  @media (max-width: $midWidth) {
    max-width: 20.25rem;
  }
}
