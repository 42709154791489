@import '../../theme/zIndexOrganizer.scss';

#MobileCamera {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: red;

  .cameraDenied {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--ion-background-color-dark);
    padding: 1.5rem;
    color: #fff;
    text-align: center;
  }

  .CameraBox {
    position: relative;
    height: 100%;
    width: 100vw;
  }

  .loader {
    --background: transparent;
  }

  .cameraPreview {
    width: 100vw;
    height: 100%;
    object-fit: cover;
  }
}
