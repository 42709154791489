@import '../../theme/colors';
@import '../../theme/fonts';

#ouverture {
  .homeFooter {
    background-color: var(--ion-color-secondary);
  }
  overflow-y: auto;

  .ouvertureContent {
    // --padding-bottom: 0 !important;
    .plan {
      margin: 1.875rem 1.25rem;

      .title {
        font-family: $boldFont;
        font-size: 1.563rem;
        min-width: 3.5rem;
      }

      .planImg {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 1.25rem auto;
        pointer-events: none;
      }
    }

    .actionButtons {
      padding: 0 1.25rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;

      button {
        letter-spacing: 0;
        width: 100%;
        padding: 0.75rem 0.5rem;
        border-radius: 0.5rem !important;
        font-family: $semiBoldFont;
        text-transform: none;
        font-size: 1.25rem;
        color: #fff;
        background: var(--ion-color-secondary);
        box-shadow: none;

        &:disabled {
          opacity: 0.5;
        }
      }
    }

    .photoPlaceholder {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 11.25rem;
      background-color: $disabled;
      border: solid 0.188rem #d8d8d8;
      border-radius: 0.875rem;
      margin: 1.5rem 0 1rem 0;

      .placeholderIcon {
        width: 4rem;
        height: 4rem;
        color: #d8d8d8;
      }
    }

    .sectorPicture {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 11.25rem;
      border-radius: 0.875rem;
      margin: 1.5rem 0 1rem 0;

      .picture {
        width: 100%;
        height: 100%;
        border-radius: 0.875rem;
        object-fit: cover;
        pointer-events: none;
      }
    }

    .colors {
      width: 100%;
    }
  }

  .successAlert {
    display: flex;
    position: absolute;
    flex-direction: column;
    text-align: center;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    width: 14.7rem;
    height: 14.8rem;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    margin-top: 40%;
    z-index: 10;
    padding: 2.5rem 2rem;
    border-radius: 0.5rem;
    box-shadow:
      0 0 5px -1px rgba(18, 33, 69, 0.5),
      0 0 16.5px 0 rgba(18, 33, 69, 0.6);

    .alertTitle {
      color: black;
      font-family: $semiBoldFont;
      font-size: 1.5rem;
    }

    .successNob {
      width: 2.688rem;
      height: 2.688rem;
      border-radius: 1.34rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid 0.08rem $accent;

      .successIcon {
        color: $accent;
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .dissmissButton {
      display: flex;
      font-family: $semiBoldFont;
      font-size: 1.125rem;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      color: white;
      border-radius: 0.5rem;
      background: var(--ion-color-secondary);
      padding: 0.5rem 1.5rem;
      box-shadow: none;
    }

    .successTick {
      position: absolute;
      bottom: -6%;
      left: 0;
      right: 0;
      margin: auto;
      width: 0;
      height: 0;
      border-left: 0.625rem solid transparent;
      border-right: 0.625rem solid transparent;
      border-top: 1rem solid white;
    }
  }

  .confirmAlert {
    display: flex;
    position: absolute;
    flex-direction: column;
    text-align: center;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    width: 17.75rem;
    height: 17.62rem;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    margin-top: 70%;
    z-index: 10;
    padding: 2rem 2rem;
    border-radius: 0.5rem;
    box-shadow:
      0 0 10px -2px rgba(18, 33, 69, 0.6),
      0 0 16.5px 0 rgba(18, 33, 69, 0.8);

    .alertTitle {
      color: black;
      font-family: $semiBoldFont;
      font-size: 1.5rem;
    }

    .dangerIcon {
      color: $accent;
      width: 2.4rem;
      height: 2.4rem;
    }

    .dissmissButton {
      display: flex;
      font-family: $semiBoldFont;
      font-size: 1.125rem;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      color: white;
      border-radius: 0.5rem;
      background: var(--ion-color-secondary);
      padding: 0.5rem 1.5rem;
      box-shadow: none;
    }

    .successTick {
      position: absolute;
      bottom: -5%;
      left: 0;
      right: 0;
      margin: auto;
      width: 0;
      height: 0;
      border-left: 0.625rem solid transparent;
      border-right: 0.625rem solid transparent;
      border-top: 1rem solid white;
    }
  }
}
