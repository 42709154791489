@import '../../theme/colors';
@import '../../theme/fonts';

.ranking {
  position: relative;
  .closeIcon {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem;
    color: $light;
    width: 1.5rem;
    height: 1.5rem;
  }

  .boulderRankingTitle {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;

    font-family: $semiBoldFont;
    font-size: 1.25rem;
    margin-bottom: 2rem;
    margin-left: 1rem;
    text-transform: capitalize;
  }
}
