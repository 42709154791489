// Colors

/** black **/
$black: #000;
$black-rgb: 0, 0, 0;

/** white **/
$white: #fff;
$white-rgb: 255, 255, 255;

/** warning **/
$warning: #fff300;
$warning-rgb: 255, 243, 0;

/** ultra dark **/
$ultradark: #12275b;
$ultradark-rgb: 18, 39, 91;

/** dark **/
$dark: #1d378a;
$dark-rgb: 29, 55, 138;

/** highlight **/
$highlight: #142e82;
$highlight-rgb: 20, 46, 130;

/** medium **/
$medium: #0e46b1;
$medium-rgb: 14, 70, 177;

/** light **/
$light: #0076ff;
$light-rgb: 0, 118, 255;

/** accent **/
$accent: var(--ion-color-secondary);
$accent-rgb: var(--ion-color-secondary-rgb);

/** disabled **/
$disabled: #f4f4f4;
$disabled-rgb: 244, 244, 244;

//boulder colors
/** citrine **/
$citrine: #f7b500;
$citrine-rgb: 247, 181, 0;

/** amber **/
$amber: #fa6400;
$amber-rgb: 250, 100, 0;

/** emerald **/
$emerald: #6dd400;
$emerald-rgb: 109, 212, 0;

/** sapphire **/
$sapphire: #0091ff;
$sapphire-rgb: 0, 145, 255;

/** ruby **/
$ruby: #e02020;
$ruby-rgb: 224, 32, 32;

/** amethyst **/
$amethyst: #b620e0;
$amethyst-rgb: 182, 32, 224;

/** obsidian **/
$obsidian: #000000;
$obsidian-rgb: 0, 0, 0;

/** iolite **/
$iolite: #ff4ddd;
$iolite-rgb: 98, 54, 255;
