@import '../../../../theme/fonts';
@import '../../../../theme/colors';

.filtre_modal {
  --border-radius: 0;

  .filterContainer {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 3rem 1.25rem;

    .filter_header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 0.5rem 0 1.5rem;
      gap: 0.5rem;

      .filter_title {
        font-size: 1.5rem;
        font-family: $boldFont;
      }

      .close_icon {
        width: 2rem;
        height: 2rem;
      }
      .close_icon:active {
        color: $highlight;
      }
    }

    .filter_types {
      .individual_type {
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .filtre_title {
          font-family: $boldFont;
          font-size: 1.25rem;
          padding-bottom: 1rem;
        }

        .filtre_colors {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          flex: 1;
          align-items: center;
          justify-content: left;
          min-height: 2.75rem;
          margin-top: 0.75rem;

          .individual_color {
            width: 2rem;
            height: 2rem;
            border-radius: 0.5rem;
            margin: 0.5rem 0.35rem;
          }
        }
      }
    }
  }

  .applyContainer {
    padding: 0.5rem 1.25rem 1rem 1.25rem;

    .save_button {
      font-family: $semiBoldFont;
      font-size: 1.125rem;
      height: 2.5rem;
      --border-radius: 0.625rem;
      --padding-end: 0.438rem;
      --padding-start: 0.438rem;
      --padding-top: 0.5rem;
      --padding-bottom: 0.5rem;
      --border-width: 0.063rem;
      --color: white;
      --background: var(--ion-color-primary);
      text-transform: none;
    }

    .reset_filtre {
      margin: 0.625rem auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 7.188rem;
      font-family: $semiBoldFont;
      font-size: 1.15rem;

      .reset_icon {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
    .reset_filtre:active {
      color: $highlight;
      .reset_icon {
        color: $highlight;
      }
    }
  }
}
