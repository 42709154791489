@import '../../theme/fonts';
@import '../../theme/colors';

#Profile {
  @media (prefers-color-scheme: dark) {
    .darkMode {
      width: 100%;
      height: max(1.7rem, env(safe-area-inset-top));
      background: white;
      position: absolute;
      background: linear-gradient(rgba(62, 104, 190, 0.6), rgba(62, 104, 190, 0));
      top: 0;
      left: 0;
      z-index: 1000;
    }
  }

  ion-content {
    --padding-top: max(1.87rem, env(safe-area-inset-top));
  }

  .profileFooter {
    background-color: #2a324b;
  }

  .profil_back_button {
    position: fixed;
    top: env(safe-area-inset-top);
    margin: 0.75rem 1rem 2.5rem;
    height: 2rem;
    width: 2rem;
    z-index: 100;

    .back_button {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  .profilContent {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .profil_main {
      padding-top: 0.5rem;
      .profilButtons {
        width: 100%;
        padding: 1rem 1.25rem 0rem;

        .profilButton {
          background: #{$light};
          font-family: $semiBoldFont;
          font-size: 1.25rem;
          height: 38px;
          width: 100%;
          color: #fff;
          text-transform: none;
          border-radius: 0.5rem;
          padding: 0.4rem 1rem;
          letter-spacing: 0;

          span {
            font-family: $regularFont;
          }
        }
      }
    }

    .pictureAndEdition {
      position: relative;
      display: flex;
      flex-direction: row;
      width: 10rem;
      height: 10rem;
      margin: 2rem auto 0rem;
      justify-content: center;
      align-items: center;

      .editPicture {
        position: absolute;
        right: 0.5rem;
        bottom: 1.2rem;
        font-size: 2rem;
        padding: 0.5rem;
        background-color: $light;
        color: white;
        border-radius: 50%;
      }

      .picture {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .donuts {
          box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.015);
        }

        .pictureImg {
          width: 8rem;
          height: 8rem;
          border-radius: 50%;
        }
      }

      .editIcons {
        position: absolute;
        top: 0;
        right: 0;
        margin: 2rem 0;
        display: flex;
        flex-direction: row;
        color: $dark;
        .editIcon {
          width: 1.375rem;
          height: 1.375rem;
          margin-left: 0.438rem;
          margin-top: 0.438rem;
        }
      }
    }
    .profil_name {
      font-family: $boldFont;
      font-size: 1.5rem;
      text-align: center;
      color: rgba(0, 0, 0, 0.85);
      padding-bottom: 1rem;
    }
    .profil_pseudo {
      font-family: $mediumFont;
      text-align: center;
      padding-bottom: 28.5px;
    }
    .tagsTitle {
      padding-left: 2.5rem;
      padding-bottom: 0.5rem;
      font-family: $boldFont;
      margin-top: 1.813rem;
      font-size: 1.25rem;
      text-transform: capitalize;
    }
    .tagLegend {
      font-family: $regularFont;
      font-size: 1rem;
      width: 100%;
      padding-left: 2.5rem;
      margin-bottom: 1rem;
      .goals,
      .style {
        display: flex;
        flex-direction: row;
        align-items: center;
        .goalColor,
        .styleColor {
          width: 1rem;
          height: 1rem;
          margin-right: 1rem;
          border-radius: 0.25rem;
          border: solid 0.063rem $accent;
          background-color: $medium;
        }
        .styleColor {
          background-color: $emerald;
        }
      }
    }
    .profilTags {
      padding-left: 1.25rem;
    }
    .profil_edit_line {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
      margin: 1rem 0;
    }

    .profil_edit {
      flex: 0.7;
      color: $light;
      font-family: $boldFont;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
    .profil_edit:active {
      color: $highlight;
    }

    .bio {
      display: flex;
      padding-top: 20px;
      padding-bottom: 10px;
    }
    .bioTitle {
      flex: 0.3;
      text-align: right;
      padding-right: 16px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 18px;
      font-weight: bold;
    }
    .bioInput {
      border: none;
      flex: 0.7;
      background-color: white;
      min-width: 40%;
      font-family: $regularFont;
    }

    .deleteButton {
      width: 100%;
      text-align: center;
      font-size: 1rem;
      margin: 1.5rem 0;
    }
    .deleteButton:active {
      color: $highlight;
    }
  }

  .appVersion {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    font-size: 0.9rem;
    opacity: 0.5;
    padding: 0.5rem 1.5rem;
  }
}
