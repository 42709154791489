@import '../../../../theme/fonts';
@import '../../../../theme/colors';

.filtreOuverture {
  // --padding-top: 1.34rem;
  // --padding-bottom: 1.34rem;
  // --padding-end: 1.25rem;
  // --padding-start: 1.25rem;
  padding: 1.34rem 1.25rem;
  position: relative;

  .backButton {
    position: absolute;
    margin: 1.34rem 1.25rem;
    top: 0;
    right: 0;
    width: 1.25rem;
    height: 1.25rem;
    z-index: 10000;
    color: #000;
  }

  .filterTypes {
    position: relative;

    .individualType {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      position: relative;
      margin-bottom: 1rem;

      .sectorNames {
        font-size: 1.4rem;
        font-family: $regularFont;
      }

      .filterTitle {
        font-family: $boldFont;
        font-size: 1.25rem;
      }

      .filtreColors {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex: 1;
        align-items: center;
        justify-content: space-between;
        min-height: 2.75rem;
        margin-top: 0.75rem;

        .individualColor {
          width: 1.875rem;
          height: 1.875rem;
          border-radius: 0.5rem;
        }
      }
    }
  }

  .nextButton,
  .addButton {
    font-family: $semiBoldFont;
    font-size: 1.125rem;
    --padding-top: 0.75rem;
    --padding-bottom: 0.75rem;
    --padding-start: 0.5rem;
    --padding-end: 0.5rem;
    --border-width: 0.063rem;
    --color: white;
    --background: var(--ion-color-secondary);
    --box-shadow: none;
    text-transform: none;
    margin: 0.5rem 0 1.25rem 0;
    height: 2.5rem;
  }

  .addButton {
    width: 35%;
  }
}
