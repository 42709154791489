@import '../../theme/colors';
@import '../../theme/fonts';

.home {
  ion-backdrop {
    opacity: 0;
    background: var(--ion-color-primary);
    z-index: 100;
  }
}

#UserBestBouldersModal {
  --width: 90vw;
  --height: 80vh;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --background: white;
  .usersRanking {
    padding: 0;
    min-width: 0;
    max-width: 100%;
    height: 100%;
    .ranking {
      margin: 0;
      height: 100%;
      padding-top: 1.25rem;
      .userList {
        max-height: 100%;
        padding: 0 1rem 5rem 1rem;
      }
    }
  }
}
