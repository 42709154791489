@import '../../../../theme/fonts';
@import '../../../../theme/sizes';
@import '../../../../theme/colors';

.homeShopContainer {
  padding: 1rem 0.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 100vw;

  .sectionName {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;

    .title {
      font-family: $boldFont;
      font-size: 1.5rem;
      margin-left: 1.25rem;
      text-transform: capitalize;
    }
  }

  .shopItems {
    width: 100%;
    border-radius: 0.4rem;
    margin: 0.37rem 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-wrap: 1;

    .shopItem {
      position: relative;
      width: 28%;
      height: 4.5rem;
      border: solid 0.063px $black;
      border-radius: 0.5rem;
      margin: 0.5rem;
      display: flex;
      flex-direction: column;

      justify-content: center;
      align-items: center;

      font-family: $semiBoldFont;
      font-size: 1rem;
      color: $black;

      .shopIcon {
        font-size: 2rem;
        line-height: 2.188rem;
      }
      .shopLabel {
        line-height: 1.5rem;
      }
    }

    .shopItem:active {
      background-color: $medium;
    }

    .pressed {
      background-color: $accent;
      color: white;
    }
  }
}
