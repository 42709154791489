@import '../../../../theme/fonts';
@import '../../../../theme/colors';

.colors {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}
