@import '../../theme/fonts';
@import '../../theme/sizes';
@import '../../theme/colors';

$fontSize: 0.8rem;

.sector_item {
  margin: 0.625rem 0;
  position: relative;
  display: flex;
  flex-direction: row;
  width: 10.62rem;
  min-width: 10.62rem;
  max-width: 10.62rem;
  height: 14.063rem;
  min-height: 14.063rem;
  max-height: 14.063rem;
  border-radius: 1.063rem;

  @media (max-width: $midWidth) {
    width: 9.75rem;
    min-width: 9.75rem;
    max-width: 9.75rem;
    height: 14.063rem;
    min-height: 14.063rem;
    max-height: 14.063rem;
  }

  .gradient {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(255, 255, 255, 0) 20%);
    border-radius: 1.063rem;
  }

  .headerPart {
    position: absolute;
    top: 0.688rem;
    left: 0.75rem;
    right: 0.75rem;
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    justify-content: space-between;
    align-items: center;

    .daily_date,
    .sectorNames {
      background-color: rgba(0, 0, 0, 0.6);
      border-radius: 0.25rem;
      padding: 0.25rem;
    }
  }

  .daily_date {
    color: white;
    font-family: $semiBoldFont;
    text-transform: capitalize;
    font-size: $fontSize;
  }

  .sectorNames {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    gap: 0.25rem;
    max-width: 45%;

    .sectorName {
      color: white;
      font-family: $semiBoldFont;
      text-transform: capitalize;
      font-size: $fontSize;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .sectorNumber {
      color: white;
      font-family: $semiBoldFont;
      text-transform: capitalize;
      font-size: $fontSize;
    }
  }

  .location_icon {
    position: absolute;
    color: white;
    bottom: 0;
    right: 0;
    margin: 0.688rem 0.875rem;
    font-family: $boldFont;
    text-transform: capitalize;
    font-size: 0.875rem;
    width: 1.5rem;
    height: 1.5rem;
  }

  .boulderColor {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0.688rem 1rem;
    max-width: 5.5rem;
    flex-wrap: wrap;

    .individual_color {
      border-radius: 9.375rem;
      margin-right: 0.5rem;
      margin-top: 0.5rem;
      width: 0.75rem;
      height: 0.75rem;
    }
  }

  .sector_img {
    width: 100%;
    height: 100%;
    border-radius: 1.063rem;
    object-fit: cover;
    pointer-events: none;
  }
}
