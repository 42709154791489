@import '../../../../theme/fonts';

#BlocDetailsModal {
  --border-radius: 1.25rem 1.25rem 0 0;
  &::part(backdrop) {
    background-color: #000;
    visibility: visible;
    z-index: 1;
  }

  &::part(handle) {
    width: 5rem;
    margin-top: 1rem;
    z-index: 1000000;
  }

  &::part(content) {
    border-top-left-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
  }

  .backgroundHandle {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2.25rem;
    z-index: 100000;
    background-color: #fff;
  }

  .modal-wrapper {
    bottom: 0;
    width: 100%;
    height: 300px;
    position: absolute;
    z-index: -10;
  }

  ion-content {
    --padding-top: 2.5rem;
    --padding-bottom: 4rem;
    --padding-end: 1.25rem;
    --padding-start: 1.25rem;
    color: white;
    display: flex;
    flex-direction: column;
    z-index: 10000;

    .valider_bloc {
      margin-left: 1rem;
    }

    .reactions {
      margin-left: 1rem;
    }

    .divTitle,
    .difficultiesTitle {
      font-family: $boldFont;
      font-size: 1.25rem;
    }

    .difficultiesTitle {
      margin: 1.25rem;
      margin-left: 0.25rem;
    }

    .textHelp {
      margin: 0.5rem 0 0;
      font-size: 1rem;
      font-family: $regularFont;
    }

    .add_coach {
      margin-left: 1rem;

      .seance_date {
        background-color: #e4ecff;
        border-radius: 0.438rem;
        width: 21.87rem;
        height: 1.875rem;
        margin-top: 0.625rem;
        margin-bottom: 1.25rem;
      }
    }

    .profilTags {
      .tagTitle {
        font-family: $semiBoldFont;
        font-size: 1.25rem;
        padding: 0.875rem 0rem 0rem;
      }
    }

    .coach_button {
      width: 23.75rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      ion-button {
        height: 2.875rem !important;
        min-width: 8.188rem !important;
        --border-radius: 0.5rem;
        font-family: $semiBoldFont;
        text-transform: none;
        font-size: 1rem;
        --box-shadow: none;
        --background: white;
        color: var(--ion-color-primary);
      }
    }

    .videoDemo,
    .photoDemo {
      width: 100%;
      height: 70%;
      margin-top: 0.688rem;
      border: 2px solid var(--ion-color-primary);
      border-radius: 0.5rem;
    }

    .photoDemo {
      object-fit: cover;
      pointer-events: none;
    }

    .sectorPlan {
      margin: 5rem 0;
      border: none;
      pointer-events: none;
    }

    .boulderDifficulties {
      display: flex;
      flex-direction: row;
      margin: 1.5rem 0 1.31rem 0;
      justify-content: left;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;

      .boulderDifficulty {
        width: 1.875rem;
        height: 1.875rem;
        border-radius: 0.5rem;
        margin: 0.563rem;
      }
    }
    .comments {
      font-size: 0.813rem;
      margin-left: 0.813rem;
    }
  }
}

.modal-wrapper {
  bottom: 0;
  width: 10%;
  height: 30px;
  position: absolute;
}
