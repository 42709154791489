.loaderBox {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  height: 100%;

  &.fullscreen {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100vh;
  }
}
