@import '../../../../theme/colors';
@import '../../../../theme/fonts';

.Connection {
  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 2rem;

    .contentLogin,
    .contentActions {
      display: flex;
      flex-direction: column;

      .loginError {
        color: red;
        font-size: 12px;
        margin-left: 0.4rem;
        margin-top: 0.4rem;
      }

      .loginPassword {
        display: flex;
        flex-direction: column;
        position: relative;
      }

      .inputRightIcon {
        position: absolute;
        top: 0;
        bottom: 11px;
        right: 0;
        margin: auto 1rem;
        height: 1.2rem;
        width: 1.2rem;
        color: white;
      }

      .inputLogin {
        background-color: $dark;
        border: solid white 1px;
        color: white;
      }

      .contentLoginBtn {
        background-color: white;
        color: $light;
        margin-top: 1rem;

        &:disabled {
          opacity: 0.25;
        }
      }

      .forgotPassword {
        color: #fff;
        font-family: $semiBoldFont;
        font-size: 1rem;
      }

      input,
      button {
        margin-bottom: 0.8rem;
        border-radius: 0.5rem;
        padding: 0.4rem;
        height: 42px;
      }

      input {
        line-height: 5;
        font-size: 0.875rem;
        font-family: $regularFont20;
        &::-webkit-input-placeholder {
          font-family: $lightFont20;
        }
      }

      input:nth-of-type(2) {
        margin: unset;
      }
    }
  }
}
