@import '../../theme/colors';
@import '../../theme/fonts';

.notifyQrScan {
  position: fixed;
  top: -150px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  height: 135px;
  z-index: 1000;
  background-color: #0b49c4;
  border-radius: 10px;
  padding: 15px;
  color: rgb(255, 255, 255);
  transition: top 0.2s ease-in-out;
  font-family: $semiBoldFont;
  font-size: 15px;
  background-image: url('../../assets/img/background-top-boulder.png');
  background-repeat: no-repeat;
  background-position: 5px 0;
  background-size: 900px;
  letter-spacing: 0.5px;
  box-shadow: 5px 5px 25px 0px rgba(0, 0, 0, 0.5);
  // triangle at the bottom
  &::before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #0b49c4;
  }

  .welcome {
    position: relative;
    width: 100%;
    text-align: center;
    margin-top: 20px;
    font-size: 18px;
  }
  .title {
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 30px;
  }
}

.notifyQrScan.open {
  top: 3.5rem;
  transition: top 0.2s ease-in-out;
}
