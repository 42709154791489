@import '../../../theme/fonts';

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  color: white;
  text-align: center;
  height: 4.99rem !important;
  --background: transparent !important;
  background-color: var(--ion-color-primary);

  .footer_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin-top: 0.6rem;
    padding: 0 0.6rem;
  }

  .footer_content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    text-decoration: none;
  }

  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    --padding-bottom: 0;
    --padding-end: 0;
    padding: 0;
    ion-item {
      --inner-padding-end: 0;
      --inner-padding-start: 0;
    }
  }

  .footer_icon {
    height: 1.43rem;
    width: 1.43rem;
    margin-bottom: 0.3rem;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    &.disabled {
      opacity: 0.25;
    }
  }

  .footer_text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-family: $regularFont20 !important;
    font-size: 0.5rem !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: white;

    &.disabled {
      opacity: 0.25;
    }
  }

  .dot {
    position: absolute;
    bottom: -0.5rem;
    left: 0;
    right: 0;
    margin: auto;
    width: 0.24rem;
    height: 0.24rem;
    background-color: white;
    border-radius: 50%;
  }
}
