@import '../../theme/fonts';
@import '../../theme/colors';

.CreateAccount {
  background-color: $dark;
  display: flex;
  flex-direction: column;
  padding: 2rem 1.5rem;
  max-height: 100%;
  overflow: auto !important;

  .LoginHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: white;
    position: relative;

    h1,
    p {
      width: 100%;
    }

    h1 {
      font-size: 60px;
      line-height: 1;
      font-family: $boldFont;
    }

    p {
      width: 75%;
      line-height: 20px;
      font-size: 1em;
      font-family: $regularFont;
      margin: 0;
      white-space: break-spaces;
    }

    img {
      width: 7.5rem;
      padding-bottom: 1rem;
    }

    .skipOnboarding {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 100;

      ion-icon {
        width: 1.5em;
        height: 1.5em;
      }
    }
  }

  .createAccountBox {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 1rem 0rem;

    input {
      background-color: $dark;
      border: solid white 1px;
      color: white;
      font-size: 0.875rem;
      font-family: $regularFont20;
      &::-webkit-input-placeholder {
        font-family: $lightFont20;
      }
    }

    input,
    button {
      margin-bottom: 0.8rem;
      border-radius: 0.5rem;
      padding: 0.4rem;
      height: 42px;
    }

    .loginPassword {
      display: flex;
      flex-direction: column;
      position: relative;

      input {
        padding-right: 42px;
      }
    }

    .inputRightIcon {
      position: absolute;
      top: 0;
      bottom: 11px;
      right: 0;
      margin: auto 1rem;
      height: 1.2rem;
      width: 1.2rem;
      color: white;
    }

    .contentLoginBtn {
      background-color: white;
      color: $light;
      margin-top: 1rem;

      &:disabled {
        opacity: 0.25;
      }
    }
    .back {
      background-color: $light;
      color: white;

      &:disabled {
        opacity: 0.25;
      }
    }

    .userInfoForm {
      display: flex;
      flex-direction: column;

      input:nth-child(-n + 2) {
        margin-bottom: 1rem;
      }

      input {
        border-radius: 0.5rem;
        padding: 0.4rem;
        height: 42px;
        width: 100%;
        background-color: transparent;
        border: solid white 1px;
        color: white;
        font-size: 0.875rem;
        font-family: $regularFont20;
      }

      input::placeholder {
        color: white;
        font-size: 0.875rem;
        font-family: $regularFont20;
      }
    }
  }

  .checkboxesBox {
    display: flex;
    flex-direction: column;
    padding: 1rem 0rem 0.5rem;
    align-items: flex-start;
    gap: 1rem;

    .checkboxItem {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;

      ion-checkbox {
        --size: 1.5rem;
        --checkbox-background-checked: transparent;
        --checkbox-background: transparent;
      }

      ion-checkbox::part(container) {
        border-radius: 0.25rem;
        border: 1px solid #fff;
      }
    }

    .checkboxLabel {
      color: #fff;
      font-family: $regularFont;

      a {
        color: #fff;
        font-weight: 700;
        font-family: $regularFont;
        text-decoration: underline;
      }
    }
  }

  .loginError {
    color: red;
    font-size: 1rem;
    margin-left: 0.4rem;
    margin-top: 0.4rem;
  }

  .loginFooter {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    ion-icon {
      width: 10rem;
      height: 2rem;
    }

    p {
      padding-top: 0.5rem;
      font-family: $boldFont;
      margin: 0;
      color: white;
      line-height: 28px;
      white-space: break-spaces;
      font-size: 1.75rem;
    }
  }
}
