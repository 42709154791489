$font-path: '/assets/fonts/';
$fontNumber: 15;

@mixin font-face($name, $font-family, $number) {
  @font-face {
    font-family: '#{$name}';
    src: url('#{$font-path}#{$font-family}#{$number}.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
}

/***    Regular    ***/
$regularFont: 'SharpGrotesk-Book';
@include font-face($regularFont, $regularFont, $fontNumber);

$regularFont20: 'SharpGrotesk-Book-20';
@include font-face($regularFont20, $regularFont, 20);

/***    Light    ***/
$lightFont: 'SharpGrotesk-Light';
@include font-face($lightFont, $lightFont, $fontNumber);

$lightFont20: 'SharpGrotesk-Light-20';
@include font-face($lightFont20, $lightFont, 20);

/***    Thin    ***/
$thinFont: 'SharpGrotesk-Thin';
@include font-face($thinFont, $thinFont, $fontNumber);

$thinFont20: 'SharpGrotesk-Thin-20';
@include font-face($thinFont20, $thinFont, $fontNumber);

/***    Semi Bold    ***/
$semiBoldFont: 'SharpGrotesk-SemiBold';
@include font-face($semiBoldFont, $semiBoldFont, $fontNumber);

$semiBoldFont20: 'SharpGrotesk-SemiBold-20';
@include font-face($semiBoldFont20, $semiBoldFont, 20);

/***    Medium    ***/
$mediumFont: 'SharpGrotesk-Medium';
@include font-face($mediumFont, $mediumFont, $fontNumber);

$mediumFont20: 'SharpGrotesk-Medium-20';
@include font-face($mediumFont20, $mediumFont, 20);

/***    Black    ***/
$blackFont: 'SharpGrotesk-Black';
@include font-face($blackFont, $blackFont, $fontNumber);

/***    Bold    ***/
$boldFont: 'SharpGrotesk-Bold';
@include font-face($boldFont, $boldFont, $fontNumber);
