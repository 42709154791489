@import '../../theme/colors';
@import '../../theme/fonts';

.maCartePage {
  --background: white !important;
  .maCarteContent {
    --overflow: hidden;
  }
  // .profil_back_button {
  //   position: absolute;
  //   margin: 1.6rem 1rem;
  //   height: 1.87rem;
  //   width: 1.87rem;
  //   z-index: 100;
  //   .back_button {
  //     height: 1.5rem;
  //     width: 1.5rem;
  //   }
  // }
  .maCartePhoto {
    position: relative;
    font-size: 7.5rem;
    text-align: center;
  }
  .picture {
    display: flex;
    justify-content: center;
  }
  .maCartePhotoImg {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  .maCarteName {
    margin-top: -0.8rem;
    text-align: center;
    font-family: $mediumFont;
    font-size: 1.5rem;
  }
  .maCarteLine {
    text-align: center;
    font-family: $mediumFont;
    font-size: 1.1rem;
  }
  .maCarteHello {
    text-align: center;
    font-family: $mediumFont;
    font-size: 2.375rem;
  }
  .maCarteHello::first-letter {
    text-transform: capitalize;
  }
  .maCarteTicketMain {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 2rem;
    .maCarteTicket {
      display: flex;
      flex: 0.38;
      justify-content: flex-end;
    }
    .maCarteTicketTextMain {
      flex: 0.62;
      margin-left: 0.5rem;
      .maCarteTicketText {
        font-weight: 500;
        font-family: $mediumFont;
      }
    }
  }
  .maCarteButtons {
    position: absolute;
    bottom: 90px;
    width: 100%;
    margin-top: 0.45rem;
  }
  .maCarteButton {
    font-family: $semiBoldFont;
    text-transform: none;
    font-size: 1.25rem;
    --background: var(--ion-color-secondary);
    --box-shadow: none;
    --padding-top: 0.75rem;
    --padding-bottom: 0.75rem;
    --padding-start: 0.5rem;
    --padding-end: 0.5rem;
    margin: 0rem 1rem;
    padding-right: 2rem;
    width: 100%;
    overflow: hidden;
    --border-radius: 10px;
    span {
      font-family: $semiBoldFont;
    }
  }
  .maCarteButtons.notConnected {
    position: relative;
    top: 0;
    // margin-top: 1rem;
    width: 100%;
  }
  .connect-button {
    margin: 1rem;
  }
  .maCarteButtonHorizontal {
    margin: 0;
    width: 100%;
    padding: 0;
  }
  .flexHorizontal {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 0 1rem;
    gap: 1rem;
    margin: 1rem 0;
  }
  .maCarteLogout {
    --background: red;
  }
  .profileFooter {
    background-color: #2a324b;
  }
}

.maCartePage {
  height: 100vh;
  width: 100vw;

  .profil_back_button {
    position: absolute;
    top: env(safe-area-inset-top);
    margin: 0.75rem 1rem;
    height: 2rem;
    width: 2rem;
    z-index: 100;

    .back_button {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  .maCarteLogedContent {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 1.25rem;
    padding-bottom: 5rem;
    overflow-y: auto;
    background-color: white;

    .body {
      .bodyContainer {
        overflow-y: auto;
        overflow-x: hidden;
        border-radius: 1.313rem;

        .idContainer {
          border: solid 0.375rem $disabled;
          border-radius: 1.313rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          background-color: white;

          .pictureAndQrc {
            display: flex;
            flex-direction: row;

            .picture {
              width: 50%;
              display: flex;
              justify-content: center;
              align-items: center;

              .iconProfil {
                height: 6.25rem;
                width: 6.25rem;
              }
            }
            .qrcContainer {
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              width: 50%;
              border-left: solid 0.188rem $disabled;
              position: relative;

              .spinnerLoader {
                position: absolute;
                top: 0.125rem;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(0, 0, 0, 0.25);
                width: 100%;
                height: 9.93rem;
                display: flex;
                align-items: center;
                justify-content: center;

                ion-spinner {
                  width: 4rem;
                  height: 4rem;
                  color: $light;
                }
              }
            }
          }

          .maCarteName {
            width: 100%;
            padding: 1rem 0 0.375rem;
            border-radius: 0 0 0.7rem 0.7rem;
            background-color: $disabled;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 1.625rem;
            font-family: $semiBoldFont;
          }
        }

        .hintToLog {
          margin-top: 1.25rem;
        }
      }
    }

    .maCarteFooter {
      display: flex;
      flex-direction: row;
      width: 100%;
      background-color: white;
      justify-content: space-between;
      padding: 1.75rem 0;

      .shopButton {
        width: 8.375rem;
        background-color: $light;
        height: 2.875rem;
        min-height: 2.875rem;
        max-height: 2.875rem;
        padding: 0.625rem;
        border-radius: 0.5rem;

        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        .shopIcon {
          height: 1.5rem;
          width: 1.5rem;
          color: white;
        }
        .shopButtonName {
          font-size: 1.125rem;
          font-family: $semiBoldFont;
          color: white;
        }
      }

      .shopButton:active {
        background-color: $highlight;
      }

      .classesButtons {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        .classeButtonsHint {
          font-size: 1.125rem;
          font-family: $semiBoldFont;
          color: black;
        }

        .classeButtonsContainer {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;

          .classeButtonSearch,
          .classeButtonBookings {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 2.875rem;
            width: 2.875rem;
            background-color: $light;
            border-radius: 0.5rem;

            .shopIcon {
              height: 1.5rem;
              width: 1.5rem;
              color: white;
            }
            .classSearchHint {
              position: absolute;
              bottom: -30%;
              font-size: 0.563rem;
              text-transform: uppercase;
            }
          }

          .classeButtonSearch {
            margin-left: 0.5rem;
          }

          .classeButtonSearch:active {
            background-color: $highlight;
          }

          .classeButtonBookings {
            margin-left: 1rem;
          }
          .classeButtonBookings:active {
            background-color: $highlight;
          }
        }
      }
    }
  }
}
