@import '../../theme/fonts';
@import '../../theme/colors';

.action_nobs {
  position: absolute;
  bottom: env(safe-area-inset-bottom);
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 10;
  padding: 0.938rem;
  transition: transform 0.25s 0s ease;

  .action_nobs_side {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    flex: none;

    .auditAndPoint {
      display: flex;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      right: 0;
      bottom: 0;
      height: 3.188rem;
      border-radius: 1.625rem;
      color: white;
      width: 40%;
      margin-bottom: 0.625rem;

      .nobContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.625rem;
        padding: 0rem;
        position: relative;
        z-index: 1000000;

        .thumbsUp {
          position: absolute;
          top: 0;
          right: 0;
          border: solid;
          height: 1.188rem;
          width: 1.188rem;
          border-width: 0.063rem;
          border-color: white;
          border-radius: 0.594rem;
          background-color: red;
          z-index: 1000000;
          margin-top: -0.3rem;
          margin-right: -0.2rem;

          display: flex;
          align-items: center;
          justify-content: center;

          .thumbsUpIcon {
            position: absolute;
            height: 1.25rem;
            width: 1.25rem;
            margin-bottom: 0.075rem;
          }
        }

        .smiley_nob,
        .top_nob,
        .flash_nob,
        .thumb_nob {
          min-width: 2.688rem;
          min-height: 2.688rem;
          border-radius: 13.125rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border: solid;
          border-width: 0.125rem;
          border-color: white;
          color: white;
          z-index: 10000;
        }

        .smiley_nob_icon,
        .top_nob_icon,
        .flash_nob_icon {
          color: white;
          width: 1.25rem;
          height: 1.25rem;
        }

        .thumb_nob_icon {
          color: white;
          width: 2.4rem;
          height: 2.4rem;
        }
      }
    }
  }

  .nobContainer {
    z-index: 10000;

    .large_nob,
    .nob_clap {
      width: 2.688rem;
      height: 2.688rem;
      border-radius: 13.125rem;
      margin: 0.625rem 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid;
      border-width: 0.125rem;
      border-color: white;
      color: white;
      z-index: 10000;

      .large_nob_icon {
        color: white;
        width: 2rem;
        height: 2rem;
      }

      .next_nob_icon {
        color: white;
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .next_nob_left,
    .next_nob_right {
      width: 2.688rem;
      height: 2.688rem;
      border-radius: 13.125rem;
      margin: 0.625rem 0;
      display: flex;
      align-items: center;
      border: solid;
      border-width: 0.125rem;
      border-color: white;
      color: white;
      z-index: 10000;

      .large_nob_icon {
        color: white;
        width: 2rem;
        height: 2rem;
      }

      .next_nob_left_icon,
      .next_nob_right_icon {
        color: white;
        width: 1.5rem;
        height: 1.5rem;
        margin-left: 0.35rem;
      }

      .next_nob_right_icon {
        margin-left: 0;
        margin-left: 0;
      }
    }

    .next_nob_right {
      justify-content: center;
    }

    .small_nob {
      width: 2.063rem;
      height: 2.063rem;
      border-radius: 13.125rem;
      margin: 0.625rem 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid;
      border-width: 0.125rem;
      border-color: white;
      z-index: 10000;
    }

    .small_nob_icon {
      color: white;
      width: 1.438rem;
      height: 1.438rem;
    }

    .empty_nob {
      width: 2rem;
      height: 2rem;
      border-radius: 13.125rem;
      margin: 0.625rem 0;
    }

    .boulderNobItem {
      &.filled {
        background-color: var(--ion-color-primary);
      }
      &.disabled {
        opacity: 0.5;
      }
    }
    .popup {
      visibility: visible;
      position: absolute;
      width: 120px;
      background-color: white;
      color: black;
      z-index: 90;
      text-align: center;
      padding: 0.5rem;
      border-radius: 0.5rem;
      font-family: $semiBoldFont;
      box-shadow:
        0 0 10px -2px rgba(18, 33, 69, 0.5),
        0 0 30px 0 rgba(18, 33, 69, 0.6);
      bottom: 3.5rem;
    }
    .alertTick {
      position: absolute;
      left: 30%;
      width: 1rem;
      height: 1rem;
      border-left: 0.5rem solid transparent;
      border-right: 0.5rem solid transparent;
      z-index: 91;
      bottom: 2.5rem;
      border-top: 0.5rem solid white;
    }
    .popoupBackdrop {
      background-color: rgba(0, 0, 0, 0);
      position: absolute;
      top: -800px;
      bottom: -600px;
      left: -600px;
      right: -600px;
      &.hidden {
        visibility: hidden;
      }
    }
  }
}
