@import '../../theme/fonts';
@import '../../theme/sizes';
@import '../../theme/colors';

#Room {
  .roomFooter {
    background-color: var(--ion-color-primary);
  }
}

.room_content {
  // overflow-y: auto;
  .buddyboulder_logo {
    font-family: $regularFont !important;
    font-size: 1.875rem !important;
    color: var(--ion-color-primary);
    margin: 2.875rem 1.25rem 0.5rem 1.25rem;
    min-width: 16rem;
  }

  .colorSelector {
    width: 100%;
    margin-bottom: 0.25rem;

    .selectorTitle {
      font-family: $semiBoldFont;
      font-size: 1.25rem;
      padding: 0 1.25rem;
      width: 100%;
    }

    .filtreColor {
      width: 100%;
      padding: 0 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      height: 4rem;

      .color {
        width: 1.875rem;
        height: 1.875rem;
        border-radius: 0.5rem;
      }
    }
  }

  .selectBoxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
    justify-content: space-between;
    margin: 0 auto;
    width: 90%;
    max-width: 90%;
  }

  .filter_button {
    letter-spacing: 0;
    font-family: $semiBoldFont;
    font-size: 18px;
    --border-radius: 0.5rem;
    --padding-end: 0.438rem;
    --padding-start: 0.438rem;
    --padding-top: 0.5rem;
    --padding-bottom: 0.5rem;
    --border-width: 0.063rem;
    --color: var(--ion-color-primary);
    --border-color: #000;
    max-height: 2.5rem;
    min-height: 2.5rem;
    text-transform: none;
    margin: 0 auto;
    width: 93%;
  }

  .room_plan {
    margin: 1.875rem 1.25rem;

    .title {
      font-family: $boldFont;
      font-size: 1.563rem;
      min-width: 3.5rem;
    }

    .plan_img {
      width: 100%;
      margin: 1.25rem auto;

      img {
        pointer-events: none;
      }
    }
  }

  .sector_list {
    padding: 0.563rem 1.25rem;
    .hint_filter {
      margin-bottom: 0.275rem;
      font-family: $regularFont;
      line-height: 1.2;
      font-size: 1rem;
    }

    .flex_list {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      flex: 1;
      margin: 0 auto;
      overflow: auto;

      @media (max-width: $maxWidth) {
        max-width: 22.12rem;
      }
      @media (max-width: $midWidth) {
        max-width: 20.25rem;
      }
    }
  }

  ion-spinner {
    width: 100%;
    overflow: hidden;
  }

  .infinitScroll {
    height: 0.625rem;
  }
}
